<template>
    <div>
        <div class="card site_footer mb_50">
            <div class="card_heading">
                <h4 class="heading2">Work from Areas</h4>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="sitefooter-checkbox">
                        <div class="form_check_box d-inline-flex align-items-center">
                            <input type="checkbox" value="Home" aria-label="..." id="work_from_home" class="form-check-input"
                                v-model="client_data.work_area" name="work_area" v-validate="'required'"
                                 @click="checkSelected($event)" data-vv-as="Work from area"  />
                            <label for="work_from_home"> Home </label>
                        </div>
                        <div class="form_check_box d-inline-flex align-items-center">
                            <input type="checkbox" value="Office" aria-label="..." id="work_from_office"
                                class="form-check-input" v-model="client_data.work_area" name="work_area"
                                v-validate="'required'" data-vv-as="Work from area"
                                @click="checkSelected($event)" />
                            <label for="work_from_office"> Office</label>
                        </div>
                        <div class="form_check_box d-inline-flex align-items-center">
                            <input type="checkbox" value="Anywhere" aria-label="..." id="work_from_anywhere"
                                class="form-check-input" v-model="client_data.work_area" name="work_area"
                                v-validate="'required'" data-vv-as="Work from area"
                                @click="checkSelected($event)" />
                            <label for="work_from_anywhere"> Anywhere </label>
                        </div>
                        <div class="form_check_box d-inline-flex align-items-center">
                            <input type="checkbox" value="Lab" aria-label="..." id="work_from_lab" class="form-check-input"
                                v-model="client_data.work_area" name="work_area" v-validate="'required'" data-vv-as="Work from area"
                                @click="checkSelected($event)" />
                            <label for="work_from_lab"> Lab </label>
                        </div>
                        <div class="form_check_box d-inline-flex align-items-center">
                            <input type="checkbox" value="Travel" aria-label="..." id="work_from_travel" class="form-check-input"
                                v-model="client_data.work_area" name="work_area" v-validate="'required'" data-vv-as="Work from area"
                                @click="checkSelected($event)" />
                            <label for="work_from_travel"> Travel </label>
                        </div>
                    </div>
                    <small class="error text-danger" v-show="errors.has('addCientForm.work_area')">
                        {{ errors.first("addCientForm.work_area") }}
                    </small>
                </div>
            </div>
        </div>
        <div id="accordionPanelsStayOpenExample">
            <div class="accordion-item">
                <div v-show="client_data.work_from_home" class="card site_footer mb_50">
                    <div class="card_heading">
                        <h4 class="heading2 accordion-header" id="panelsStayOpen-headingTwo">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseTwo">
                                Work from Home
                                <div class="down_up_arrow">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" role="presentation"
                                        class="vs__open-indicator">
                                        <path
                                            d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z">
                                        </path>
                                    </svg>
                                </div>
                            </button>
                        </h4>
                    </div>
                    <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse marg_top show"
                        aria-labelledby="panelsStayOpen-headingTwo">
                        <div class="accordion-body">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group select_image cont_img">
                                        <label class="form-label">Icon <svg  v-tooltip=" 'You can upload png/svg/jpg file upto 1 mb and has dimensions upto 200 X 200 pixels ' " xmlns="http://www.w3.org/2000/svg" data-name="Group 13109" width="16" height="16" viewBox="0 0 14 18.346" ><path data-name="Path 9426" d="M0 0h14v14H0z" style="fill: none" ></path><path data-name="Path 9427" d="M9 16a7 7 0 1 1 7-7 7 7 0 0 1-7 7zm0-1.4A5.6 5.6 0 1 0 3.4 9 5.6 5.6 0 0 0 9 14.6zm-.7-9.1h1.4v1.4H8.3zm0 2.8h1.4v4.2H8.3z" transform="translate(-2 -1.654)" style="fill: rgb(109, 109, 109)" ></path></svg>
                                        </label>
                                        <div class="input-group align-items-center">
                                            <input type="file" class="form-control" id="work_from_home_icon"
                                                @change=" onFileChange($event, 'work_from_home_icon') "
                                                @click="initializeFile($event, 'Home')"
                                                v-validate=" client_data.work_from_home ? !client_data.work_area_details.Home.icon ? 'required' : 'ext:svg,jpg,png,jpeg|maxdimensions:200,200|size:1024' : '' "
                                                name="work_from_home_icon" data-vv-as="icon" ref="wfhIcon" />                                            
                                                <div class="img_and_cross">
                                                    <img id="111" v-show="client_data.id" :src="
                                                    !client_data.work_area_details.Home.icon.includes(
                                                        'data:image'
                                                    ) &&
                                                    client_data.work_area_details.Home.icon != ''
                                                        ? client_data.work_area_details.Home.icon &&
                                                        !client_data.new_work_from_home_icon
                                                        ? base_url +
                                                            '/public/client/images/work_areas/' +
                                                            client_data.work_area_details.Home.icon
                                                        : require('@/assets/images/homepage/error.png')
                                                        : client_data.work_area_details.Home.icon
                                                    " style="width: 60px; margin-top: 5px" />
                                                    <img id="222" v-show="!client_data.id" :src="
                                                    client_data.work_area_details.Home.icon
                                                        ? client_data.work_area_details.Home.icon.includes(
                                                            'data:image'
                                                        )
                                                        ? client_data.work_area_details.Home.icon
                                                        : require('@/assets/images/homepage/error.png')
                                                        : require('@/assets/images/homepage/home.png')
                                                    " style="width: 60px; margin-top: 5px" />
                                                    <div class="drop_x_img" v-if="client_data.new_work_from_home_icon">
                                                        <a href="javascript:void(0);" @click="removeIcon('Home')">X</a>
                                                    </div>
                                                </div>
                                        </div>
                                        <small id="home_icon_image_error" class="error text-danger ms-0 ms-sm-3" v-show="
                                        errors.has('addCientForm.work_from_home_icon')
                                        ">
                                            {{
                                            errors.first("addCientForm.work_from_home_icon")
                                            }}
                                        </small>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="" class="form-label">Title</label>
                                        <input type="text" placeholder="Title" class="form-control"
                                            v-model="client_data.work_area_details.Home.title"
                                            name="work_from_home_title" id="work_from_home_title" v-validate="
                                            client_data.work_from_home
                                                ? 'required|max:25'
                                                : ''
                                            " data-vv-as="title" />
                                        <small class="error text-danger" v-show="
                                        errors.has('addCientForm.work_from_home_title')
                                        ">
                                            {{
                                            errors.first("addCientForm.work_from_home_title")
                                            }}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <label for="description" class="form-label">
                                    Description
                                </label>
                                <input type="text" class="form-control" placeholder="Description"
                                    name="work_from_home_desc" id="work_from_home_desc"
                                    v-model="client_data.work_area_details.Home.description" v-validate="
                                    client_data.work_from_home ? 'required|max:100' : ''
                                    " data-vv-as="description" />
                                <small class="error text-danger"
                                    v-show="errors.has('addCientForm.work_from_home_desc')">
                                    {{ errors.first("addCientForm.work_from_home_desc") }}
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="accordion-item">
                <div v-show="client_data.work_from_office" class="card site_footer mb_50">
                    <div class="card_heading">
                        <h4 class="heading2 accordion-header" id="panelsStayOpen-headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
                                aria-controls="panelsStayOpen-collapseOne">
                                Work from Office
                                <div class="down_up_arrow">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" role="presentation"
                                        class="vs__open-indicator">
                                        <path
                                            d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z">
                                        </path>
                                    </svg>
                                </div>
                            </button>
                        </h4>
                    </div>
                    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse marg_top show"
                        aria-labelledby="panelsStayOpen-headingOne">
                        <div class="accordion-body">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group select_image cont_img">
                                        <label class="form-label">Icon <svg  v-tooltip=" 'You can upload png/svg/jpg file upto 1 mb and has dimensions upto 200 X 200 pixels ' " xmlns="http://www.w3.org/2000/svg" data-name="Group 13109" width="16" height="16" viewBox="0 0 14 18.346" ><path data-name="Path 9426" d="M0 0h14v14H0z" style="fill: none" ></path><path data-name="Path 9427" d="M9 16a7 7 0 1 1 7-7 7 7 0 0 1-7 7zm0-1.4A5.6 5.6 0 1 0 3.4 9 5.6 5.6 0 0 0 9 14.6zm-.7-9.1h1.4v1.4H8.3zm0 2.8h1.4v4.2H8.3z" transform="translate(-2 -1.654)" style="fill: rgb(109, 109, 109)" ></path></svg>
                                        </label>
                                        <div class="input-group align-items-center">
                                            <input type="file" class="form-control" id="work_from_office_icon"
                                                @change="
                                                onFileChange($event, 'work_from_office_icon')
                                                " @click="initializeFile($event, 'Office')" v-validate="
                                                    client_data.work_from_office
                                                    ? !client_data.work_area_details.Office.icon
                                                        ? 'required'
                                                        : 'ext:svg,jpg,png,jpeg|maxdimensions:200,200|size:1024'
                                                    : ''
                                                " name="work_from_office_icon" data-vv-as="icon" ref="wfoIcon" />
                                                <div class="img_and_cross">
                                                    <img v-show="client_data.id" :src="
                                                    !client_data.work_area_details.Office.icon.includes(
                                                        'data:image'
                                                    ) &&
                                                    client_data.work_area_details.Office.icon != ''
                                                        ? client_data.work_area_details.Office.icon &&
                                                        !client_data.new_work_from_office_icon
                                                        ? base_url +
                                                            '/public/client/images/work_areas/' +
                                                            client_data.work_area_details.Office.icon
                                                        : require('@/assets/images/homepage/error.png')
                                                        : client_data.work_area_details.Office.icon
                                                    " style="width: 60px; margin-top: 5px" />
                                                    <img v-show="!client_data.id" :src="
                                                    client_data.work_area_details.Office.icon
                                                        ? client_data.work_area_details.Office.icon.includes(
                                                            'data:image'
                                                        )
                                                        ? client_data.work_area_details.Office.icon
                                                        : require('@/assets/images/homepage/error.png')
                                                        : require('@/assets/images/homepage/office.png')
                                                    " style="width: 60px; margin-top: 5px" />
                                                    <div class="drop_x_img" v-if="client_data.new_work_from_office_icon">
                                                        <a href="javascript:void(0);" @click="removeIcon('Office')">X</a>
                                                    </div>
                                                </div>
                                        </div>
                                        <small id="office_icon_image_error" class="error text-danger ms-0 ms-sm-3"
                                            v-show="
                                            errors.has('addCientForm.work_from_office_icon')
                                            ">
                                            {{
                                            errors.first("addCientForm.work_from_office_icon")
                                            }}
                                        </small>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="" class="form-label">Title</label>
                                        <input type="text" placeholder="Title" name="work_from_office_title"
                                            id="work_from_office_title" class="form-control"
                                            v-model="client_data.work_area_details.Office.title" v-validate="
                                            client_data.work_from_office
                                                ? 'required|max:25'
                                                : ''
                                            " data-vv-as="title" />
                                        <small class="error text-danger" v-show="
                                        errors.has('addCientForm.work_from_office_title')
                                        ">
                                            {{
                                            errors.first(
                                            "addCientForm.work_from_office_title"
                                            )
                                            }}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <label for="description" class="form-label">
                                    Description
                                </label>
                                <input type="text" class="form-control" placeholder="Description"
                                    name="work_from_office_desc" id="work_from_office_desc" v-model="
                                    client_data.work_area_details.Office.description
                                    " v-validate="
                                    client_data.work_from_office ? 'required|max:100' : ''
                                    " data-vv-as="description" />
                                <small class="error text-danger" v-show="
                                errors.has('addCientForm.work_from_office_desc')
                                ">
                                    {{ errors.first("addCientForm.work_from_office_desc") }}
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="accordion-item">
                <div v-show="client_data.work_from_anywhere" class="card site_footer mb_50">
                    <div class="card_heading">
                        <h4 class="heading2 accordion-header" id="panelsStayOpen-headingThree">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseThree">
                                Work from Anywhere
                                <div class="down_up_arrow">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" role="presentation"
                                        class="vs__open-indicator">
                                        <path
                                            d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z">
                                        </path>
                                    </svg>
                                </div>
                            </button>
                        </h4>
                    </div>
                    <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse marg_top show"
                        aria-labelledby="panelsStayOpen-headingThree">
                        <div class="accordion-body">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group select_image cont_img">
                                        <label class="form-label">Icon <svg  v-tooltip=" 'You can upload png/svg/jpg file upto 1 mb and has dimensions upto 200 X 200 pixels ' " xmlns="http://www.w3.org/2000/svg" data-name="Group 13109" width="16" height="16" viewBox="0 0 14 18.346" ><path data-name="Path 9426" d="M0 0h14v14H0z" style="fill: none" ></path><path data-name="Path 9427" d="M9 16a7 7 0 1 1 7-7 7 7 0 0 1-7 7zm0-1.4A5.6 5.6 0 1 0 3.4 9 5.6 5.6 0 0 0 9 14.6zm-.7-9.1h1.4v1.4H8.3zm0 2.8h1.4v4.2H8.3z" transform="translate(-2 -1.654)" style="fill: rgb(109, 109, 109)" ></path></svg>
                                        </label>
                                        <div class="input-group align-items-center">
                                            <input type="file" class="form-control" id="work_from_anywhere_icon"
                                                @change="
                                                onFileChange($event, 'work_from_anywhere_icon')
                                                " @click="initializeFile($event, 'Anywhere')" v-validate="
                                                    client_data.work_from_anywhere
                                                    ? !client_data.work_area_details.Anywhere.icon
                                                        ? 'required'
                                                        : 'ext:svg,jpg,png,jpeg|maxdimensions:200,200|size:1024'
                                                    : ''
                                                " name="work_from_anywhere_icon" data-vv-as="icon" ref="wfaIcon" />
                                                <div class="img_and_cross">
                                            <img v-show="client_data.id" :src="
                                            !client_data.work_area_details.Anywhere.icon.includes(
                                                'data:image'
                                            ) &&
                                            client_data.work_area_details.Anywhere.icon !=
                                                ''
                                                ? client_data.work_area_details.Anywhere
                                                    .icon &&
                                                !client_data.new_work_from_anywhere_icon
                                                ? base_url +
                                                    '/public/client/images/work_areas/' +
                                                    client_data.work_area_details.Anywhere
                                                    .icon
                                                : require('@/assets/images/homepage/error.png')
                                                : client_data.work_area_details.Anywhere.icon
                                            " style="width: 60px; margin-top: 5px" />
                                            <img v-show="!client_data.id" :src="
                                            client_data.work_area_details.Anywhere.icon
                                                ? client_data.work_area_details.Anywhere.icon.includes(
                                                    'data:image'
                                                )
                                                ? client_data.work_area_details.Anywhere
                                                    .icon
                                                : require('@/assets/images/homepage/error.png')
                                                : require('@/assets/images/homepage/anywhere.png')
                                            " style="width: 60px; margin-top: 5px" />
                                            <div class="drop_x_img" v-if="client_data.new_work_from_anywhere_icon">
                                                <a href="javascript:void(0);" @click="removeIcon('Anywhere')">X</a>
                                            </div>
                                                </div>
                                        </div>
                                        <small id="anywhere_icon_image_error" class="error text-danger ms-0 ms-sm-3"
                                            v-show="
                                            errors.has('addCientForm.work_from_anywhere_icon')
                                            ">
                                            {{
                                            errors.first(
                                            "addCientForm.work_from_anywhere_icon"
                                            )
                                            }}
                                        </small>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="" class="form-label">Title</label>
                                        <input type="text" placeholder="Title" class="form-control" v-model="
                                        client_data.work_area_details.Anywhere.title
                                        " name="work_from_anywhere_title" id="work_from_anywhere_title" v-validate="
                                        client_data.work_from_anywhere
                                            ? 'required|max:25'
                                            : ''
                                        " data-vv-as="title" />
                                        <small class="error text-danger" v-show="
                                        errors.has(
                                            'addCientForm.work_from_anywhere_title'
                                        )
                                        ">
                                            {{
                                            errors.first(
                                            "addCientForm.work_from_anywhere_title"
                                            )
                                            }}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <label for="description" class="form-label">
                                    Description
                                </label>
                                <div class="col-sm-12">
                                    <input type="text" class="form-control" placeholder="Description"
                                        name="work_from_anywhere_desc" id="work_from_anywhere_desc" v-model="
                                        client_data.work_area_details.Anywhere.description
                                        " v-validate="
                                            client_data.work_from_anywhere
                                            ? 'required|max:100'
                                            : ''
                                        " data-vv-as="description" />
                                    <small class="error text-danger" v-show="
                                    errors.has('addCientForm.work_from_anywhere_desc')
                                    ">{{
                                    errors.first("addCientForm.work_from_anywhere_desc")
                                    }}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="accordion-item">
                <div v-show="client_data.work_from_lab" class="card site_footer mb_50">
                    <div class="card_heading">
                        <h4 class="heading2 accordion-header" id="panelsStayOpen-headingFour">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseFour">
                                Work from Lab
                                <div class="down_up_arrow">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" role="presentation"
                                        class="vs__open-indicator">
                                        <path
                                            d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z">
                                        </path>
                                    </svg>
                                </div>
                            </button>
                        </h4>
                    </div>
                    <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse marg_top show"
                        aria-labelledby="panelsStayOpen-headingFour">
                        <div class="accordion-body">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group select_image cont_img">
                                        <label class="form-label">Icon <svg  v-tooltip=" 'You can upload png/svg/jpg file upto 1 mb and has dimensions upto 200 X 200 pixels ' " xmlns="http://www.w3.org/2000/svg" data-name="Group 13109" width="16" height="16" viewBox="0 0 14 18.346" ><path data-name="Path 9426" d="M0 0h14v14H0z" style="fill: none" ></path><path data-name="Path 9427" d="M9 16a7 7 0 1 1 7-7 7 7 0 0 1-7 7zm0-1.4A5.6 5.6 0 1 0 3.4 9 5.6 5.6 0 0 0 9 14.6zm-.7-9.1h1.4v1.4H8.3zm0 2.8h1.4v4.2H8.3z" transform="translate(-2 -1.654)" style="fill: rgb(109, 109, 109)" ></path></svg>
                                        </label>
                                        <div class="input-group align-items-center">
                                            <input type="file" class="form-control" id="work_from_lab_icon"
                                                @change="
                                                onFileChange($event, 'work_from_lab_icon')
                                                " @click="initializeFile($event, 'Lab')" v-validate="
                                                    client_data.work_from_lab
                                                    ? !client_data.work_area_details.Lab.icon
                                                        ? 'required'
                                                        : 'ext:svg,jpg,png,jpeg|maxdimensions:200,200|size:1024'
                                                    : ''
                                                " name="work_from_lab_icon" data-vv-as="icon" ref="wflIcon" />                                          
                                                <div class="img_and_cross">
                                                    <img v-show="client_data.id" :src="
                                                    !client_data.work_area_details.Lab.icon.includes(
                                                        'data:image'
                                                    ) &&
                                                    client_data.work_area_details.Lab.icon != ''
                                                        ? client_data.work_area_details.Lab.icon &&
                                                        !client_data.new_work_from_lab_icon
                                                        ? base_url +
                                                            '/public/client/images/work_areas/' +
                                                            client_data.work_area_details.Lab.icon
                                                        : require('@/assets/images/homepage/error.png')
                                                        : client_data.work_area_details.Lab.icon
                                                    " style="width: 60px; margin-top: 5px" />
                                                    <img v-show="!client_data.id" :src="
                                                    client_data.work_area_details.Lab.icon
                                                        ? client_data.work_area_details.Lab.icon.includes(
                                                            'data:image'
                                                        )
                                                        ? client_data.work_area_details.Lab.icon
                                                        : require('@/assets/images/homepage/error.png')
                                                        : require('@/assets/images/homepage/lab.png')
                                                    " style="width: 60px; margin-top: 5px" />
                                                    <div class="drop_x_img" v-if="client_data.new_work_from_lab_icon">
                                                        <a href="javascript:void(0);" @click="removeIcon('Lab')">X</a>
                                                    </div>
                                                </div>
                                        </div>
                                        <small id="lab_icon_image_error" class="error text-danger ms-0 ms-sm-3" v-show="
                                        errors.has('addCientForm.work_from_lab_icon')
                                        ">
                                            {{
                                            errors.first("addCientForm.work_from_lab_icon")
                                            }}
                                        </small>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="" class="form-label">Title</label>
                                        <input type="text" placeholder="Title" class="form-control"
                                            v-model="client_data.work_area_details.Lab.title" name="work_from_lab_title"
                                            id="work_from_lab_title" v-validate="
                                            client_data.work_from_lab ? 'required|max:25' : ''
                                            " data-vv-as="title" />
                                        <small class="error text-danger" v-show="
                                        errors.has('addCientForm.work_from_lab_title')
                                        ">
                                            {{
                                            errors.first("addCientForm.work_from_lab_title")
                                            }}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <label for="description" class="form-label">
                                    Description
                                </label>
                                <input type="text" class="form-control" placeholder="Description"
                                    name="work_from_lab_desc" id="work_from_lab_desc"
                                    v-model="client_data.work_area_details.Lab.description" v-validate="
                                    client_data.work_from_lab ? 'required|max:100' : ''
                                    " data-vv-as="description" />
                                <small class="error text-danger" v-show="errors.has('addCientForm.work_from_lab_desc')">
                                    {{ errors.first("addCientForm.work_from_lab_desc") }}
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="accordion-item">
                <div v-show="client_data.work_from_travel" class="card site_footer mb_50">
                    <div class="card_heading">
                        <h4 class="heading2 accordion-header" id="panelsStayOpen-headingFive">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseFive">
                                Travel for Work
                                <div class="down_up_arrow">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" role="presentation"
                                        class="vs__open-indicator">
                                        <path
                                            d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z">
                                        </path>
                                    </svg>
                                </div>
                            </button>
                        </h4>
                    </div>
                    <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse marg_top show"
                        aria-labelledby="panelsStayOpen-headingFive">
                        <div class="accordion-body">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group select_image cont_img">
                                        <label class="form-label">Icon <svg  v-tooltip=" 'You can upload png/svg/jpg file upto 1 mb and has dimensions upto 200 X 200 pixels ' " xmlns="http://www.w3.org/2000/svg" data-name="Group 13109" width="16" height="16" viewBox="0 0 14 18.346" ><path data-name="Path 9426" d="M0 0h14v14H0z" style="fill: none" ></path><path data-name="Path 9427" d="M9 16a7 7 0 1 1 7-7 7 7 0 0 1-7 7zm0-1.4A5.6 5.6 0 1 0 3.4 9 5.6 5.6 0 0 0 9 14.6zm-.7-9.1h1.4v1.4H8.3zm0 2.8h1.4v4.2H8.3z" transform="translate(-2 -1.654)" style="fill: rgb(109, 109, 109)" ></path></svg>
                                        </label>
                                        <div class="input-group align-items-center">
                                            <input type="file" class="form-control" id="work_from_travel_icon"
                                                @change="
                                                onFileChange($event, 'work_from_travel_icon')
                                                " @click="initializeFile($event, 'Travel')" v-validate="
                                                    client_data.work_from_travel
                                                    ? !client_data.work_area_details.Travel.icon
                                                        ? 'required'
                                                        : 'ext:svg,jpg,png,jpeg|maxdimensions:200,200|size:1024'
                                                    : ''
                                                " name="work_from_travel_icon" data-vv-as="icon" ref="wftIcon" />                                          
                                                <div class="img_and_cross">
                                                    <img v-show="client_data.id" :src="
                                                    !client_data.work_area_details.Travel.icon.includes(
                                                        'data:image'
                                                    ) &&
                                                    client_data.work_area_details.Travel.icon != ''
                                                        ? client_data.work_area_details.Travel.icon &&
                                                        !client_data.new_work_from_travel_icon
                                                        ? base_url +
                                                            '/public/client/images/work_areas/' +
                                                            client_data.work_area_details.Travel.icon
                                                        : require('@/assets/images/homepage/error.png')
                                                        : client_data.work_area_details.Travel.icon
                                                    " style="width: 60px; margin-top: 5px" />
                                                    <img v-show="!client_data.id" :src="
                                                    client_data.work_area_details.Travel.icon
                                                        ? client_data.work_area_details.Travel.icon.includes(
                                                            'data:image'
                                                        )
                                                        ? client_data.work_area_details.Travel.icon
                                                        : require('@/assets/images/homepage/error.png')
                                                        : require('@/assets/images/homepage/travel-for-work-home.png')
                                                    " style="width: 60px; margin-top: 5px" />
                                                    <div class="drop_x_img" v-if="client_data.new_work_from_travel_icon">
                                                        <a href="javascript:void(0);" @click="removeIcon('Travel')">X</a>
                                                    </div>
                                                </div>
                                        </div>
                                        <small id="travel_icon_image_error" class="error text-danger ms-0 ms-sm-3" v-show="
                                        errors.has('addCientForm.work_from_travel_icon')
                                        ">
                                            {{
                                            errors.first("addCientForm.work_from_travel_icon")
                                            }}
                                        </small>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="" class="form-label">Title</label>
                                        <input type="text" placeholder="Title" class="form-control"
                                            v-model="client_data.work_area_details.Travel.title" name="work_from_travel_title"
                                            id="work_from_travel_title" v-validate="
                                            client_data.work_from_travel ? 'required|max:25' : ''
                                            " data-vv-as="title" />
                                        <small class="error text-danger" v-show="
                                        errors.has('addCientForm.work_from_travel_title')
                                        ">
                                            {{
                                            errors.first("addCientForm.work_from_travel_title")
                                            }}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <label for="description" class="form-label">
                                    Description
                                </label>
                                <input type="text" class="form-control" placeholder="Description"
                                    name="work_from_travel_desc" id="work_from_travel_desc"
                                    v-model="client_data.work_area_details.Travel.description" v-validate="
                                    client_data.work_from_travel ? 'required|max:100' : ''
                                    " data-vv-as="description" />
                                <small class="error text-danger" v-show="errors.has('addCientForm.work_from_travel_desc')">
                                    {{ errors.first("addCientForm.work_from_travel_desc") }}
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    inject: ["parentValidator"],
    created() {
        this.$validator = this.parentValidator
    },
    name: "ClientWorkFromAreas",
    data() {
        return {
            workAreaDetails:    this.$store.getters["ClientsConfig/workAreaDetails"],
        }
    },
    props: ['client_data', "base_url"],
    beforeMount() {
        if (this.$route.params.id == undefined) {      
        // Json functions are used so that original values doesnt get changed
        this.client_data.work_area_details =  JSON.parse(JSON.stringify(this.workAreaDetails));
        }
    },
    methods: {
        initializeFile(event, type) {
            this.workEvent = event.target.id;
            this.workType = type;
            this.$nextTick(() => {
                document.body.focus = this.checkFile;
            });           
        },
        checkFile() {
            var theFile = document.getElementById(this.workEvent);
            if (theFile.value.length) console.log("Files Loaded");
            else this.removeIcon(this.workType);

            document.body.focus = null;
        },
        removeIcon(work_from_type) {
            switch (work_from_type) {
                case "Home":
                    this.$refs.wfhIcon.type = "text";
                    this.$refs.wfhIcon.type = "file";
                    document.getElementById("home_icon_image_error").style.display = "none";
                    this.client_data.new_work_from_home_icon = false;
                    this.client_data.work_area_details.Home.icon = this.client_data.work_area_details.Home.oldIcon;
                    break;

                case "Office":
                    this.$refs.wfoIcon.type = "text";
                    this.$refs.wfoIcon.type = "file";
                    document.getElementById("office_icon_image_error").style.display = "none";
                    this.client_data.new_work_from_office_icon = false;
                    this.client_data.work_area_details.Office.icon = this.client_data.work_area_details.Office.oldIcon;
                    break;

                case "Lab":
                    this.$refs.wflIcon.type = "text";
                    this.$refs.wflIcon.type = "file";
                    document.getElementById("lab_icon_image_error").style.display = "none";
                    this.client_data.new_work_from_lab_icon = false;
                    this.client_data.work_area_details.Lab.icon = this.client_data.work_area_details.Lab.oldIcon;
                    break;

                case "Anywhere":
                    this.$refs.wfaIcon.type = "text";
                    this.$refs.wfaIcon.type = "file";
                    document.getElementById("anywhere_icon_image_error").style.display = "none";
                    this.client_data.new_work_from_anywhere_icon = false;
                    this.client_data.work_area_details.Anywhere.icon = this.client_data.work_area_details.Anywhere.oldIcon;
                    break;

                case "Travel":
                    this.$refs.wftIcon.type = "text";
                    this.$refs.wftIcon.type = "file";
                    document.getElementById("travel_icon_image_error").style.display = "none";
                    this.client_data.new_work_from_travel_icon = false;
                    this.client_data.work_area_details.Travel.icon = this.client_data.work_area_details.Travel.oldIcon;
                    break;

                default:
                    break;
            }
        },
        onFileChange($event, type){
            this.$parent.onFileChange($event, type);
        },
        async checkSelected(event) {
            let id = event.target.id;
            let value = event.target.value;
            if (event.target.checked) {
                this.client_data[id] = true;
                this.client_data.work_area_details[value] = JSON.parse(JSON.stringify(this.workAreaDetails[value]));
                this.removeIcon(this.workType);
            } else {
                const confirmComplete = await this.$parent.$refs.confirmDialogue.show({
                    title: "",
                    message: "Are you sure you want to disable this  ?",
                    okButton: "Yes",
                    cancelButton: "No"
                });
                // On Confirm
                if (confirmComplete) {
                    this.client_data[id] = false;
                } else {
                    //On cancle of confirmation popup it will reset the data
                    this.client_data.work_area.push(event.target.value);
                    event.target.checked =  true;
                    this.client_data[id] = true;
                }
            }
        },
    }
};
</script>