<template>
  <div class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <main>
      <div class="main_content align-items-center">
        <div class="row justify-content-between mb_40">
          <div class="col-md-8">
            <div class="heading">
              <h2 v-if="buttonUpdate">Update Client</h2>
              <h2 v-else>Add New Client</h2>
              <h3> PBE Management Dashboard / {{ buttonUpdate ? "Update" : "Add" }} Client </h3>
            </div>
          </div>
        </div>

        <form method="post" @submit.prevent="addClient" data-vv-scope="addCientForm" >
          <div class="card site_footer mb_50">
            <div class="card_heading">
              <h4 class="heading2">Account Details</h4>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group mb_10">
                  <label for="" class="form-label" >Company Name</label >
                  <input type="text" class="form-control" id="exampleInputEmail1" name="company_name" aria-describedby="emailHelp" placeholder="Company Name" v-model="client_data.company_name" v-validate="'required|max:30'" data-vv-as="company name" />
                  <small class="error text-danger" v-show="errors.has('addCientForm.company_name')" >
                    {{ errors.first("addCientForm.company_name") }}
                  </small>
                </div>
                <input type="hidden" v-model="client_data.u_id" />
                <div class="sitefooter-checkbox mb_20">
                  <div class="form_check_box d-flex align-items-center">
                    <input type="checkbox" value="" aria-label="..." id="flexCheckDefault" class="form-check-input" v-model="client_data.enable_reminder" />
                    <label for="flexCheckDefault"> Enable Reminders </label>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="" class="form-label" >Company Code</label >
                  <input type="text" class="form-control" id="exampleInputEmail1" name="company_code" aria-describedby="emailHelp" placeholder="Company Code" v-model="client_data.company_code" v-validate="'required|max:10'" data-vv-as="Company Code" />
                  <small class="error text-danger" v-show="errors.has('addCientForm.company_code')" >
                    {{ errors.first("addCientForm.company_code") }}
                  </small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 mb-20">
                <div class="form-group">
                  <label for="" class="form-label" >Client Type</label >
                  <v-select v-model="client_data.client_type" label="name" :reduce="(client_types) => client_types.id" :options="client_types" name="client_type" placeholder="Select Type" v-validate="'required'" data-vv-as="Client Type" />
                  <small class="error text-danger" v-show="errors.has('addCientForm.client_type')" >
                    {{ errors.first("addCientForm.client_type") }}
                  </small>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="" class="form-label" >Manage Self-Assessment</label >
                  <v-select v-model="client_data.manage_self_assessment_btn" label="name" :reduce="(manage_self_assessment_btn) => manage_self_assessment_btn.id" :options="manage_self_assessment_btn" name="self_assessment_button_status" placeholder="Select status" v-validate="'required'" data-vv-as="Manage Self-Assessment" />
                  <small class="error text-danger" v-show="errors.has('addCientForm.self_assessment_button_status')" >
                      {{ errors.first("addCientForm.self_assessment_button_status") }}
                    </small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group select_image">
                  <label for="" class="form-label" >Banner Image </label>
                  <div class="input-group align-items-center">
                    <input type="file" class="form-control" id="inputGroupFile02" @change="onFileChange($event, 'banner')" v-validate=" client_data.banner_image ? 'mimes:image/jpeg,image/png|size:7168' : 'required|mimes:image/jpeg,image/png|size:2048' " name="banner_image" data-vv-as="Banner" />
                    <img v-show=" client_data.id && !client_data.banner_image.includes('base64') " :src=" client_data.banner_image ? base_url + '/public/client/images/' + client_data.banner_image : '../../assets/admin/images/banner_image.png' " style="width: 60px; margin-left: 16px" />
                    <img v-show=" client_data.banner_image.includes('image/jpeg;base64') || client_data.banner_image.includes('image/png;base64') " :src=" client_data.banner_image ? client_data.banner_image : '../../assets/admin/images/banner_image.png' " style="width: 60px; margin-left: 16px" />
                  </div>
                  <small class="error text-danger" v-show="errors.has('addCientForm.banner_image')" >
                    {{ errors.first("addCientForm.banner_image") }}
                  </small>
                </div>
              </div>
              <div class="col-lg-6">
                <!-- <div class="form-group color_picker">
                  <label for="" class="form-label" >Primary Hex Color</label >
                  <input type="text" placeholder="E.g. #F0F0F1" class="form-control" v-model="client_data.hex_color" />
                  <img src="../../assets/admin/images/color-picker.png" alt="img" />
                </div> -->
                <div class="form-group color_picker">
                  <label for="" class="form-label" >Primary Hex Color</label >
                  <ColourPicker v-model="client_data.hex_color" :color="client_data.hex_color" picker="chrome"  isPrimary ref="primary_color_picker" :key="color_picker_reload" />
                  <img src="../../assets/admin/images/color-picker.png" alt="img" @click="toggleColorPicker($event)" id="primary_color_icon" />
                </div>
                <!-- <ColourPicker v-model="client_data.hex_color" :value="client_data.hex_color" picker="chrome"  /> -->
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group select_image">
                  <label for="" class="form-label" >Logo</label >
                  <div class="input-group align-items-center">
                    <input type="file" id="inputGroupFile01" class="form-control" @change="onFileChange($event, 'logo')" v-validate=" client_data.logo ? 'mimes:image/jpeg,image/png|size:2048' : 'required|mimes:image/jpeg,image/png|size:2048' " name="logo_file" data-vv-as="Logo" />
                    <img v-show=" client_data.id && !client_data.logo.includes('base64') " :src=" client_data.logo ? base_url + '/public/client/images/' + client_data.logo : '../../assets/admin/images/logo.png' " style="width: 60px; margin-left: 16px" />
                    <img v-show=" client_data.logo.includes('image/jpeg;base64') || client_data.logo.includes('image/png;base64') " :src=" client_data.logo ? client_data.logo : '../../assets/admin/images/logo.png' " style="width: 60px; margin-left: 16px" />
                  </div>
                  <small class="error text-danger" v-show="errors.has('addCientForm.logo_file')" >
                    {{ errors.first("addCientForm.logo_file") }}
                  </small>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group color_picker">
                  <label for="" class="form-label" >Secondary Hex Color</label >
                  <ColourPicker v-model="client_data.secondary_hex_color" :color="client_data.secondary_hex_color" picker="chrome" isSecondary  ref="secondary_color_picker" :key="color_picker_reload"/>
                  <img src="../../assets/admin/images/color-picker.png" alt="img" @click="toggleColorPicker($event)" id="secondary_color_icon" />
                </div>
                <!-- <div class="form-group color_picker">
                  <label for="" class="form-label" >Secondary Hex Color</label >
                  <input type="text" placeholder="E.g. #000000" class="form-control" v-model="client_data.secondary_hex_color" />
                  <img src="../../assets/admin/images/color-picker.png" alt="img" />
                </div> -->
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="" class="form-label" >Billable Cost</label >
                  <input type="text" class="form-control" id="billablecost" aria-describedby="emailHelp" placeholder="Add Billable Cost" v-model="client_data.billing_cost" v-validate="'min_value:0|decimal:2'" name="billablecost" data-vv-as="Billable Cost"/>
                  <small class="error text-danger" v-show=" errors.has( 'addCientForm.billablecost') " >
                      {{ errors.first( "addCientForm.billablecost") }}
                    </small>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group color_picker mb_20 text_in">
                  <label for="" class="form-label" >Region</label >
                  <v-select multiple v-model="client_data.region" label="name" name="Region" :reduce="(region_list) => region_list.id" :options="region_list" class="mob-b-ma" placeholder="Region" v-validate="'required'" data-vv-as="Region" />
                  <small class="error text-danger" v-show="errors.has('addCientForm.Region')" >
                    {{ errors.first("addCientForm.Region") }}
                  </small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="" class="form-label" >Default User Role</label >
                  <v-select v-model="client_data.default_user_role" label="display_name" name="default_user_role" :reduce="(userroles) => userroles.id" :options="user_roles" placeholder="Select Role" v-validate="'required'" data-vv-as="Default User Role" />
                  <small class="error text-danger" v-show="errors.has('addCientForm.default_user_role')" >
                    {{ errors.first("addCientForm.default_user_role") }}
                  </small>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="" class="form-label" >My Report Type</label >
                  <v-select v-model="client_data.report_type" label="name" name="report_type" :reduce="(report_type_list) => report_type_list.id" :options="report_type_list" placeholder="Select Report Type" v-validate="'required'" data-vv-as="My report type" />
                  <small class="error text-danger" v-show="errors.has('addCientForm.report_type')" >
                    {{ errors.first("addCientForm.report_type") }}
                  </small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="" class="form-label" >Status</label >
                  <v-select v-model="client_data.status" label="name" name="status" :reduce="(status_list) => status_list.id" :options="status_list" placeholder="Select Status" v-validate="'required'" data-vv-as="Status" />
                  <small class="error text-danger" v-show="errors.has('addCientForm.status')" >
                    {{ errors.first("addCientForm.status") }}
                  </small>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="" class="form-label" >Follow-up Status</label >
                  <v-select v-model="client_data.manage_followup_status" label="name" :reduce="(manage_followup_status) => manage_followup_status.id" :options="manage_followup_status" name="followup_status" placeholder="Select status" v-validate="'required'" data-vv-as="Follow-up Status" />
                  <small class="error text-danger" v-show="errors.has('addCientForm.followup_status')" >
                      {{ errors.first("addCientForm.followup_status") }}
                    </small>
                </div>
              </div>
          </div>
          </div>
                    <!-- Total Seats Component -->
                    <div class="card site_footer mb_50">
            <div class="card_heading">
                <h4 class="heading2">Total Seats</h4>
            </div>
            <div class="row d-none">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="" class="form-label"> Total Seats</label>
                        <input type="" class="form-control" id="total_seats" aria-describedby="emailHelp" name="total_seats"
                            placeholder="Total Seats" v-model="client_data.total_seats" v-validate="'numeric'" data-vv-as="Total Seat"/>
                        <small class="error text-danger" v-show="errors.has('addCientForm.total_seats')">
                          {{ errors.first('addCientForm.total_seats') }}
                        </small>
                    </div>                    
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                      <label for="" class="form-label"> Total Seats Used</label>
                      <input type="" class="form-control" id="total_seat_used" name="total_seat_used" aria-describedby="emailHelp"
                          placeholder="Total Seats Used" v-model="client_data.total_seats_used" v-validate="'numeric'" data-vv-as="Total Seat Used"/>
                      <small class="error text-danger" v-show="errors.has('addCientForm.total_seat_used')">
                        {{ errors.first('addCientForm.total_seat_used') }}
                      </small>
                    </div>
                </div>
            </div>
            <div class="row form-group" v-for="(singlerow, indx) in client_data.domain_seat_count" :key="indx">
                <div class="col-lg-8">
                    <div class="domain_heading font_size_13 mb_10">Domain Name</div>
                    <input type="text" class="form-control" placeholder="Domain Name" :name="'domain_name_'+indx"
                        aria-label="Recipient's username with two button addons" v-model="singlerow.domain" v-validate="'validDomainName'" data-vv-as="Domain Name"/>
                    <small class="error text-danger" v-show="errors.has('addCientForm.domain_name_'+indx)">
                      Domain Name is not valid
                    </small>
                </div>
                <div class="col-lg-6 d-none">
                    <div class="domain_heading font_size_13 mb_10 mob-top_margin">
                        Domain Seat Count
                    </div>
                    <div class="input-group">
                        <input type="" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" :name="'domain_seat_count_'+indx" v-validate="'numeric'" data-vv-as="Domain Seat Count" placeholder="Domain Seat Count" v-model="singlerow.seat_count" />
                        
                    </div>
                    <small class="error text-danger" v-show="errors.has('addCientForm.domain_seat_count_'+indx)">
                      Domain Seat Count must be valid number 
                    </small>
                </div>
                <div class="del d-flex justify-content-between mt-2 col-lg-8">
                  <a class="delete_button" type="button" v-show="indx < client_data.domain_seat_count.length && client_data.domain_seat_count.length > 1" v-on:click="removeDomain(indx)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="13.503" height="14.94"
                          viewBox="0 0 13.503 14.94">
                          <path data-name="Path 108"
                              d="M14.784 5.876 14.16 14.6a1.438 1.438 0 0 1-1.434 1.336h-5.95A1.438 1.438 0 0 1 5.342 14.6l-.623-8.724m3.594 2.875v4.313m2.876-4.313v4.313m.719-7.189V3.719A.719.719 0 0 0 11.189 3H8.313a.719.719 0 0 0-.719.719v2.157M4 5.876h11.5"
                              transform="translate(-3 -2)"
                              style=" fill: none; stroke: #cccccc; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; " />
                      </svg>
                  </a>
                  <a class="add_button" v-on:click="addDomain" v-show="indx == client_data.domain_seat_count.length - 1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16.865" height="16.865"
                    viewBox="0 0 16.865 16.865">
                      <path data-name="Icon ionic-md-add"
                      d="M23.615 16.307h-7.308v7.308h-2.249v-7.308H6.75v-2.249h7.308V6.75h2.249v7.308h7.308z"
                      transform="translate(-6.75 -6.75)" />
                    </svg>
                  </a>
                </div>
            </div>
          </div>
          <!-- Total Seats Component End -->
          
          <div class="card site_footer mb_50">
              <div class="card_heading">
                  <h4 class="heading2">Header Menu</h4>
              </div>
              <div class="row">
                  <div class="col-lg-6">
                      <div class="group_header">
                          <label class="hearder_menu_label" for="">Home</label>
                          <div class="form-group menu_cont">
                              <input type="text" placeholder="Custom Menu Text For Home" name="home_menu_text" id="home_menu_text" class="form-control" v-model="client_data.site_menu_details.Home.custom_menu_text " v-validate="'required|max:20'" />
                              <div class="tab_report toggle_switch reported for_btn_cont">
                                  <ul id="myTab1" class="nav nav-tabs">
                                      <li class="nav-item">
                                          <button id="percent-tab1" ref="Home_show" @click="toggleHeaderMenu('Home',1)" data-bs-toggle="tab" type="button" aria-controls="percent" aria-selected="true" :class="client_data.site_menu_details.Home.status == 1 ? 'nav-link active' : 'nav-link' "> Show </button>
                                      </li>
                                      <li class="nav-item">
                                          <button id="hash-tab1" @click="toggleHeaderMenu('Home',0)" data-bs-toggle="tab" type="button" aria-controls="hash" aria-selected="false" :class="client_data.site_menu_details.Home.status == 0 ? 'nav-link active' : 'nav-link' "> Hide </button>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                          <small class="error text-danger" v-show="errors.has('addCientForm.home_menu_text')">
                              {{ errors.first("addCientForm.home_menu_text") }}
                          </small>
                      </div>
                  </div>
                  <div class="col-lg-6">
                      <div class="group_header">
                          <label class="hearder_menu_label" for="">Ergo Essentials</label>
                          <div class="form-group menu_cont">
                              <input type="text" placeholder="Custom Menu Text For Ergo Essentials" name="ergo_menu_text" id="ergo_menu_text" class="form-control" v-model="client_data.site_menu_details.ErgoEssentials .custom_menu_text " data-vv-as="Custom Menu Text For Ergo Essentials" v-validate="'required|max:20'" />
                              <div class="tab_report toggle_switch reported for_btn_cont">
                                  <ul id="myTab1" class="nav nav-tabs">
                                      <li class="nav-item">
                                          <button id="percent-tab1" ref="ErgoEssentials_show" @click="toggleHeaderMenu('ErgoEssentials',1)" data-bs-toggle="tab" type="button" aria-controls="percent" aria-selected="true" :class="client_data.site_menu_details.ErgoEssentials .status == 1 ? 'nav-link active' : 'nav-link' "> Show </button>
                                      </li>
                                      <li class="nav-item">
                                          <button id="hash-tab1"  @click="toggleHeaderMenu('ErgoEssentials',0)" data-bs-toggle="tab" type="button" aria-controls="hash" aria-selected="false" :class="client_data.site_menu_details.ErgoEssentials .status == 0 ? 'nav-link active' : 'nav-link' "> Hide </button>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                          <small class="error text-danger" v-show="errors.has('addCientForm.ergo_menu_text')">
                              {{ errors.first("addCientForm.ergo_menu_text") }}
                          </small>
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-lg-6">
                      <div class="group_header">
                          <label class="hearder_menu_label" for="">Setup Guidelines</label>
                          <div class="form-group menu_cont">
                              <input type="text" placeholder="Custom Menu Text For Setup Guidelines" name="guideline_menu_text" id="guideline_menu_text" class="form-control" v-model="client_data.site_menu_details.SetupGuidelines .custom_menu_text " data-vv-as="Custom Menu Text For Setup Guidelines" v-validate="'required|max:20'" />
                              <div class="tab_report toggle_switch reported for_btn_cont">
                                  <ul id="myTab1" class="nav nav-tabs">
                                      <li class="nav-item">
                                          <button id="percent-tab1" ref="SetupGuidelines_show" @click="toggleHeaderMenu('SetupGuidelines',1)" data-bs-toggle="tab" type="button" aria-controls="percent" aria-selected="true" :class="client_data.site_menu_details.SetupGuidelines .status == 1 ? 'nav-link active' : 'nav-link' "> Show </button>
                                      </li>
                                      <li class="nav-item">
                                          <button id="hash-tab1" @click="toggleHeaderMenu('SetupGuidelines',0)"  data-bs-toggle="tab" type="button" aria-controls="hash" aria-selected="false" :class="client_data.site_menu_details.SetupGuidelines .status == 0 ? 'nav-link active' : 'nav-link' "> Hide </button>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                          <small class="error text-danger" v-show="errors.has('addCientForm.guideline_menu_text')">
                              {{ errors.first("addCientForm.guideline_menu_text") }}
                          </small>
                      </div>
                  </div>
                  <div class="col-lg-6">
                      <div class="group_header">
                          <label class="hearder_menu_label" for="">Work Better</label>
                          <div class="form-group menu_cont">
                              <input type="text" placeholder="Custom Menu Text For Work Better" name="work_better_menu_text" id="work_better_menu_text" class="form-control" v-model="client_data.site_menu_details.WorkBetter .custom_menu_text " data-vv-as="Custom Menu Text For Work Better" v-validate="'required|max:20'" />
                              <div class="tab_report toggle_switch reported for_btn_cont">
                                  <ul id="myTab1" class="nav nav-tabs">
                                      <li class="nav-item">
                                          <button id="percent-tab1" ref="WorkBetter_show" @click="toggleHeaderMenu('WorkBetter',1)" data-bs-toggle="tab" type="button" aria-controls="percent" aria-selected="true" :class="client_data.site_menu_details.WorkBetter.status == 1 ? 'nav-link active' : 'nav-link' "> Show </button>
                                      </li>
                                      <li class="nav-item">
                                          <button id="hash-tab1" @click="toggleHeaderMenu('WorkBetter',0)"  data-bs-toggle="tab" type="button" aria-controls="hash" aria-selected="false" :class="client_data.site_menu_details.WorkBetter.status == 0 ? 'nav-link active' : 'nav-link' "> Hide </button>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                          <small class="error text-danger" v-show="errors.has('addCientForm.work_better_menu_text')">
                              {{ errors.first("addCientForm.work_better_menu_text") }}
                          </small>
                      </div>
                  </div>
              </div>
              <!-- Videos -->
              <div class="row">
                  <div class="col-lg-6">
                      <div class="group_header">
                          <label class="hearder_menu_label" for="">Videos</label>
                          <div class="form-group menu_cont">
                              <input type="text" placeholder="Custom Menu Text For Videos" name="videos_menu_text" id="videos_menu_text" class="form-control" v-model="client_data.site_menu_details.Videos.custom_menu_text " data-vv-as="Custom Menu Text For Videos" v-validate="'required|max:20'" />
                              <div class="tab_report toggle_switch reported for_btn_cont">
                                  <ul id="myTab1" class="nav nav-tabs">
                                      <li class="nav-item">
                                          <button id="percent-tab1" ref="Videos_show" @click="toggleHeaderMenu('Videos',1)" data-bs-toggle="tab" type="button" aria-controls="percent" aria-selected="true" :class="client_data.site_menu_details.Videos.status == 1 ? 'nav-link active' : 'nav-link' "> Show </button>
                                      </li>
                                      <li class="nav-item">
                                          <button id="hash-tab1" @click="toggleHeaderMenu('Videos',0)" data-bs-toggle="tab" type="button" aria-controls="hash" aria-selected="false" :class="client_data.site_menu_details.Videos.status == 0 ? 'nav-link active' : 'nav-link' "> Hide </button>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                          <small class="error text-danger" v-show="errors.has('addCientForm.videos_menu_text')">
                              {{ errors.first("addCientForm.videos_menu_text") }}
                          </small>
                      </div>
                  </div>
              </div>
          </div>
          <!-- Work from Areas Component -->
          <ClientWorkFromAreas ref="ClientWorkFromAreas" 
          v-bind:client_data="client_data" v-bind:base_url="base_url"></ClientWorkFromAreas>
          <!-- Help center Component -->
          <ClientHelpCenter ref="ClientHelpCenter" v-bind:help_center_details="client_data.help_center_details" v-bind:clientId="client_data.id" v-bind:base_url="base_url"></ClientHelpCenter>
          <div class="third-section card site_footer mb_50">
              <div class="card_heading">
                  <h4 class="heading2">Video Playlist</h4>
              </div>
              <div class="row">
                  <div class="col-md-12">
                      <div class="sitefooter-checkbox">
                          <div class="form_check_box d-inline-flex align-items-center">
                              <input type="checkbox" value="Everyday Stretches" aria-label="..." id="vples"
                                  class="form-check-input" v-model="client_data.video_playlist" /><label for="vples"> Everyday
                                  Stretches</label>
                          </div>
                          <div class="form_check_box d-inline-flex align-items-center">
                              <input type="checkbox" value="Ergo Essentials" aria-label="..." id="vplee"
                                  class="form-check-input" v-model="client_data.video_playlist" /><label for="vplee"> Ergo
                                  Essentials </label>
                          </div>
                          <div class="form_check_box d-inline-flex align-items-center">
                              <input type="checkbox" value="Healthy Living" aria-label="..." id="vplhl"
                                  class="form-check-input" v-model="client_data.video_playlist" /><label for="vplhl"> Healthy
                                  Living </label>
                          </div>
                          <div class="form_check_box d-inline-flex align-items-center">
                              <input type="checkbox" value="Yoga Exercise" aria-label="..." id="vplye"
                                  class="form-check-input" v-model="client_data.video_playlist" /><label for="vplye"> Yoga
                                  Exercise </label>
                          </div>
                          <div class="form_check_box d-inline-flex align-items-center">
                              <input type="checkbox" value="Productivity Hacks" aria-label="..." id="vplph"
                                  class="form-check-input" v-model="client_data.video_playlist" /><label for="vplph">
                                  Productivity Hacks </label>
                          </div>
                          <div class="form_check_box d-inline-flex align-items-center">
                              <input type="checkbox" value="Posture Perfect" aria-label="..." id="vplpp"
                                  class="form-check-input" v-model="client_data.video_playlist" /><label for="vplpp"> Posture
                                  Perfect </label>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <!-- Schedule Request -->
          <div class="card mb_32">
            <div class="editor_heading">
              <!-- <h5 class="heading_4">Schedule Request</h5> -->
              <div class="form_check_box d-flex align-items-center pb_20">
                <input type="checkbox" value="" aria-label="..." id="schedulerequestcheck" class="form-check-input" v-model="client_data.schedule_request_status" />
                <label for="schedulerequestcheck"> Schedule Request</label>
              </div>
            </div>
            <div class="px_10">
              <ckeditor id="schedule_request_details" name="schedule_request_details" :editor="editor" v-model="client_data.schedule_request" :config="editorConfig" v-validate="client_data.schedule_request_status ? 'required' : ''" data-vv-as="Schedule Request details" @blur="onLostFocusEditor('addCientForm.schedule_request_details')"></ckeditor>
              <small class="error text-danger" v-show="errors.has('addCientForm.schedule_request_details')" >
                {{
                  errors.first("addCientForm.schedule_request_details")
                }}
              </small>
            </div>
          </div>

          <!-- Dashboard Component -->
          <!-- <ClientDashboards class="d-none" ref="ClientDashboards" ></ClientDashboards> -->
          <div class="card site_footer mb_50">
              <div class="card_heading">
                  <h4 class="heading2">Form Links</h4>
              </div>
              <div class="row">
                  <div class="col-lg-6">
                      <div class="form-group">
                          <label for="" class="form-label">
                              Self-Assessment</label>
                          <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                              placeholder="Self-Assessment" v-model="client_data.formlink_self_assessment"
                              v-validate="'required|url'" data-vv-as="Self-Assessment" name="formlink_self_assessment" />

                          <small class="error text-danger" v-show="errors.has('addCientForm.formlink_self_assessment')">
                              {{ errors.first("addCientForm.formlink_self_assessment") }}
                          </small>
                      </div>
                  </div>
                  <div class="col-lg-6">
                      <div class="form-group">
                          <label for="" class="form-label">Ergo Evaluation</label>
                          <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                              placeholder="Evaluation" v-model="client_data.formlink_ergo_eval" v-validate="'required|url'"
                              data-vv-as="Ergo Evaluation" name="formlink_ergo_eval" />
                          <small class="error text-danger" v-show="errors.has('addCientForm.formlink_ergo_eval')">
                              {{ errors.first("addCientForm.formlink_ergo_eval") }}
                          </small>
                      </div>
                  </div>
                  <div class="col-lg-6">
                      <div class="form-group">
                          <label for="" class="form-label">
                              Schedule Request</label>
                          <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                              placeholder="Schedule Request" v-model="client_data.formlink_schedule_request"
                              v-validate="'required|url'" data-vv-as="Schedule Request" name="formlink_schedule_request" />
                          <small class="error text-danger" v-show="
                            errors.has('addCientForm.formlink_schedule_request')
                          ">
                              {{ errors.first("addCientForm.formlink_schedule_request") }}
                          </small>
                      </div>
                  </div>
                  <div class="col-lg-6">
                      <div class="form-group">
                          <label for="" class="form-label">
                              Self-Assessment Follow-Up Survey</label>
                          <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                              placeholder="Self-Assessment Follow-Up Survey"
                              v-model="client_data.formlink_self_assesment_follow_up" v-validate="'required|url'"
                              data-vv-as="Self-Assessment Follow-Up Survey" name="formlink_self_assesment_follow_up" />
                          <small class="error text-danger" v-show="
                            errors.has(
                              'addCientForm.formlink_self_assesment_follow_up'
                            )
                          ">
                              {{
                              errors.first(
                              "addCientForm.formlink_self_assesment_follow_up"
                              )
                              }}
                          </small>
                      </div>
                  </div>
                  <div class="col-lg-6">
                      <div class="form-group">
                          <label for="" class="form-label">Ergo Evaluation Follow-Up Survey</label>
                          <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                              placeholder="Ergo Evaluation Follow-Up Survey"
                              v-model="client_data.formlink_ergo_eval_follow_up" v-validate="'required|url'"
                              data-vv-as="Ergo Evaluation Follow-Up Survey" name="formlink_ergo_eval_follow_up" />

                          <small class="error text-danger" v-show="
                            errors.has('addCientForm.formlink_ergo_eval_follow_up')
                          ">
                              {{
                              errors.first("addCientForm.formlink_ergo_eval_follow_up")
                              }}
                          </small>
                      </div>
                  </div>
              </div>
          </div>
          <ClientSummaryReportContent v-bind:client_data="client_data" v-bind:base_url="base_url" ref="ClientSummaryReportContent" ></ClientSummaryReportContent>
          <div class="card site_footer mb_24 editor_section">
            <div class="card_heading">
              <h4 class="heading2">Site Footer</h4>
            </div>
            <div class="row client_accordian">
              <div class="px_10">
                <ckeditor :editor="editor" v-model="client_data.site_footer" :config="siteFooterEditorConfig" ></ckeditor>
              </div>
            </div>
          </div>

          <div class="first_section mt_24 follow_ups" v-if="$route.params.id">
            <div class="card_heading">
              <h4 class="heading2">Follow-Ups</h4>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div v-for="( sa_followup, ssind ) in client_data.self_assessment_followup" :key="ssind" >
                  <div class="form-group">
                    <label class="form-label">Self-Assessment</label>
                    <input type="text" placeholder="Interval" class="form-control" v-model="sa_followup.interval" v-validate=" sa_followup.email_template_id ? 'required|numeric' : '' " v-bind:name="'self_assessment_followup_interval_' + ssind" data-vv-as="Interval" />
                    <small class="error text-danger" v-show=" errors.has( 'addCientForm.self_assessment_followup_interval_' + ssind ) " >
                      {{ errors.first( "addCientForm.self_assessment_followup_interval_" + ssind ) }}
                    </small>
                  </div>
                  <div class="form-group">
                    <label class="form-label">Email Template</label>
                    <v-select v-model="sa_followup.email_template_id" label="subject" :reduce="(sa_email_templates) => sa_email_templates.id" :options="sa_email_templates" v-bind:name=" 'self_assessment_followup_email_template_' + ssind " placeholder="Select Template" v-validate="sa_followup.interval ? 'required' : ''" data-vv-as="Email Template" />
                    <small class="error text-danger" v-show=" errors.has( 'addCientForm.self_assessment_followup_email_template_' + ssind ) " >
                      {{ errors.first( "addCientForm.self_assessment_followup_email_template_" + ssind ) }}
                    </small>
                  </div>
                  <div class="d-flex justify-content-between">
                    <a class="delete_button" type="button" v-show="ssind < client_data.self_assessment_followup.length &&client_data.self_assessment_followup.length > 1"  @click="removeSeflAssessmentFolloup(ssind)" >
                      <svg xmlns="http://www.w3.org/2000/svg" width="13.503" height="14.94" viewBox="0 0 13.503 14.94" >
                        <path data-name="Path 108" d="M14.784 5.876 14.16 14.6a1.438 1.438 0 0 1-1.434 1.336h-5.95A1.438 1.438 0 0 1 5.342 14.6l-.623-8.724m3.594 2.875v4.313m2.876-4.313v4.313m.719-7.189V3.719A.719.719 0 0 0 11.189 3H8.313a.719.719 0 0 0-.719.719v2.157M4 5.876h11.5" transform="translate(-3 -2)" style=" fill: none; stroke: #cccccc; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; " />
                      </svg>
                    </a>
                    <a class="add_button" @click="addSeflAssessmentFolloup" v-show="ssind == client_data.self_assessment_followup.length - 1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16.865" height="16.865" viewBox="0 0 16.865 16.865" >
                        <path data-name="Icon ionic-md-add" d="M23.615 16.307h-7.308v7.308h-2.249v-7.308H6.75v-2.249h7.308V6.75h2.249v7.308h7.308z" transform="translate(-6.75 -6.75)" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div v-for="( ee_followup, eeindx ) in client_data.ergo_eval_followup" :key="eeindx" >
                  <div class="form-group">
                    <label for="fuintervalee" class="form-label" >Ergo Evaluation</label >
                    <input type="text" placeholder="Interval" class="form-control" v-model="ee_followup.interval" v-validate=" ee_followup.email_template_id ? 'required|numeric' : '' " v-bind:name="'ergo_eval_followup_interval_' + eeindx" data-vv-as="Interval" />
                    <small class="error text-danger" v-show=" errors.has( 'addCientForm.ergo_eval_followup_interval_' + eeindx ) " >
                      {{ errors.first( "addCientForm.ergo_eval_followup_interval_" + eeindx ) }}
                    </small>
                  </div>
                  <div class="form-group">
                    <label for="fuemailtemp1" class="form-label" >Email Template</label >
                    <v-select v-model="ee_followup.email_template_id" label="subject" :reduce="(ee_email_templates) => ee_email_templates.id" :options="ee_email_templates" v-bind:name=" 'ergo_eval_followup_email_template_' + eeindx " placeholder="Select Template" v-validate="ee_followup.interval ? 'required' : ''" data-vv-as="Email Template" />
                    <small class="error text-danger" v-show=" errors.has( 'addCientForm.ergo_eval_followup_email_template_' + eeindx ) " >
                      {{ errors.first( "addCientForm.ergo_eval_followup_email_template_" + eeindx ) }}
                    </small>
                  </div>
                  <div class="d-flex justify-content-between">
                    <a class="delete_button" type="button" v-show="eeindx < client_data.ergo_eval_followup.length &&client_data.ergo_eval_followup.length > 1"  @click="removeErgoEvalFolloup(eeindx)" >
                      <svg xmlns="http://www.w3.org/2000/svg" width="13.503" height="14.94" viewBox="0 0 13.503 14.94" >
                       <path data-name="Path 108" d="M14.784 5.876 14.16 14.6a1.438 1.438 0 0 1-1.434 1.336h-5.95A1.438 1.438 0 0 1 5.342 14.6l-.623-8.724m3.594 2.875v4.313m2.876-4.313v4.313m.719-7.189V3.719A.719.719 0 0 0 11.189 3H8.313a.719.719 0 0 0-.719.719v2.157M4 5.876h11.5" transform="translate(-3 -2)" style=" fill: none; stroke: #cccccc; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; " />
                      </svg>
                    </a>
                    <a class="add_button" @click="addErgoEvalFolloup" v-show="eeindx == client_data.ergo_eval_followup.length - 1" >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16.865" height="16.865" viewBox="0 0 16.865 16.865" >
                       <path data-name="Icon ionic-md-add" d="M23.615 16.307h-7.308v7.308h-2.249v-7.308H6.75v-2.249h7.308V6.75h2.249v7.308h7.308z" transform="translate(-6.75 -6.75)" />
                      </svg>
                    </a>
                  </div> 
                </div>
              </div>
            </div>
          </div>

          <div class="site_footer">
            <div class="client_btn_group action_btn">
              <div class="row mt_24 justify-content-start">
                <div class="col-md-10 col-lg-6 col-xl-4 text-start">
                  <button v-if="buttonUpdate" type="submit" class="btn btn_primary" > Update </button>
                  <button v-else type="submit" class="btn btn_primary"> Add </button>
                  <router-link :to="{ name: 'all_clients' }">
                    <button href="javascript:void(0);" class="btn btn_outline ml_24" > Cancel </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </main>
    <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  </div>
</template>
<script>
import "vue-select/dist/vue-select.css";
import ClassicEditor from "ckeditor5-build-classic-image";
import ClientHelpCenter from "../../components/clients/ClientHelpCenter.vue";
import ClientWorkFromAreas from "../../components/clients/ClientWorkFromAreas.vue";
import ClientSummaryReportContent from "../../components/clients/ClientSummaryReportContent.vue";
import commonFunction from "../../mixin/commonFunction";
import ColourPicker from '@/components/CustomColorPicker'
import ConfirmDialogue from "@/components/ConfirmDialogue";

export default {
  provide () {
    return { parentValidator: this.$validator}
  },
  name: "add_client",
  components: {
    ClientHelpCenter,
    ClientWorkFromAreas,
    ConfirmDialogue,
    ColourPicker,
    ClientSummaryReportContent
  },
  mixins: [commonFunction],
  data() {
    return {
      summaryReportInfo: this.$store.getters["ClientsConfig/summaryReportInfo"],
      editor: ClassicEditor,
      editorData:
        "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>",
      editorConfig: {
        // The configuration of the editor.
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "mediaEmbed",
            "undo",
            "redo",
            "imageUpload"
          ],
        },
        mediaEmbed: {
          previewsInData:true
        },
      },
      siteFooterEditorConfig: {
        // The configuration of the editor.
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "undo",
            "redo",
          ],
        },
      },
      client_types: [
        {
          id: "1",
          name: "Ergo-Ally",
        }, {
          id: "2",
          name: "Service B",
        }, {
          id: "3",
          name: "Service C",
        },
      ],
      status_list: [
        {
          id: 1,
          name: "Active",
        }, {
          id: 0,
          name: "InActive",
        },
      ],
      report_type_list:[
        {
          id: 1,
          name: "Alchemer / Current Reports",
        }, {
          id: 2,
          name: "Qualtrics / Previous Reports",
        },{
          id: 3,
          name: "Both Reports",
        },
      ],
      manage_self_assessment_btn: [
        {
          id: "1",
          name: "Show",
        }, {
          id: "0",
          name: "Hide",
        },
      ],
      manage_followup_status: [
        {
          id: "1",
          name: "Show",
        }, {
          id: "0",
          name: "Hide",
        },
      ],
      region_list:[],
      buttonUpdate: false,
      user_roles: [],
      sa_email_templates: [],
      ee_email_templates: [],
      base_url: null,
      primary_color: "",
      color_picker_reload: 0,
      secondary_color: "",
      client_data: {
        id: "",
        u_id: "",
        company_name: "",
        company_code: "",
        enable_reminder: 0,
        client_type: "",
        status: "",
        hex_color: "#FF9500",
        secondary_hex_color: "#1A191C",
        billing_cost: "",
        region: "",
        default_user_role: "",
        report_type: "",
        total_seats: "",
        total_seats_used: "",
        domain_seat_count: [
          {
            domain: "",
            seat_count: 0,
          },
        ],
        work_area: [],
        work_from_office: false,
        work_from_home: false,
        new_work_from_home_icon: false,
        new_work_from_office_icon: false,
        new_work_from_anywhere_icon: false,
        new_work_from_lab_icon: false,
        work_from_anywhere: false,
        work_from_lab: false,
        work_from_travel: false,
        work_area_details: {
          Office: {
            oldIcon: require("@/assets/images/homepage/office.png"),
            icon: require("@/assets/images/homepage/office.png"),
            title: "Working from office?",
            description: "Discover your ideal setup, learn the best ergo practices and be on your A game every single day.",
          },
          Home: {
            oldIcon: require("@/assets/images/homepage/home.png"),
            icon: require("@/assets/images/homepage/home.png"),
            title: "Working from home?",
            description:
              "Discover the best ways to bring ergo into your home, boost your productivity and feel great!",
          },
          Anywhere: {
            oldIcon: require("@/assets/images/homepage/anywhere.png"),
            icon: require("@/assets/images/homepage/anywhere.png"),
            title: "Working from anywhere?",
            description:
              "Hello Hybrid.  Shuttling back and forth between the office and home? We’ve got you.",
          },
          Lab: {
            oldIcon: require("@/assets/images/homepage/lab.png"),
            icon: require("@/assets/images/homepage/lab.png"),
            title: "Working from lab?",
            description:
              "Learn how to navigate the variety of working environments inherent in labs to feel great.",
          },
          Travel: {
            oldIcon: require("@/assets/images/homepage/travel-for-work-home.png"),
            icon: require("@/assets/images/homepage/travel-for-work-home.png"),
            title: "Do you travel for work?",
            description: "Stay on your A-game with good ergo and healthy work practices no matter where you are.",
          },
        },
        site_menu_details: {
          Home: {
            menu: "Home",
            custom_menu_text: "Home",
            status: 1,
          },
          ErgoEssentials: {
            menu: "Ergo Essentials",
            custom_menu_text: "Ergo Essentials",
            status: 1,
          },
          SetupGuidelines: {
            menu: "Setup Guidelines",
            custom_menu_text: "Setup Guidelines",
            status: 1,
          },
          WorkBetter: {
            menu: "Work Better",
            custom_menu_text: "Work Better",
            status: 1,
          },
          Videos: {
            menu: "Videos",
            custom_menu_text: "Videos",
            status: 1,
          },
        },
        help_center_details: {
          GeneralErgo: {
            default_heading: "General Ergo",
            heading: "General Ergo",
            status: 1,
            data: [
              {
                question: "",
                answer: "",
                icon: "",
                oldIcon: "",
                newIcon: false,
              },
            ]
          },
          MyReports: {
            default_heading: "My Reports",
            heading: "My Reports",
            status: 1,
            data: [
              {
                question: "",
                answer: "",
                icon: "",
                oldIcon: "",
                newIcon: false,
              },
            ]
          },
          MyData: {
            default_heading: "My Data",
            heading: "My Data",
            status: 1,
            data: [
              {
                question: "",
                answer: "",
                icon: "",
                oldIcon: "",
                newIcon: false,
              },
            ]
          },
          AdditionalSupport: {
            default_heading: "Additional Support",
            heading: "Additional Support",
            status: 1,
            data: [
              {
                question: "",
                answer: "",
                icon: "",
                oldIcon: "",
                newIcon: false,
              },
            ]
          },
          DashboardTutorials: {
            default_heading: "Dashboard Tutorials",
            heading: "Dashboard Tutorials",
            status: 1,
            data: [
              {
                question: "",
                answer: "",
                icon: "",
                oldIcon: "",
                newIcon: false,
                vimeoVideos: [],
              },
            ]
          },
        },
        video_playlist: [ "Everyday Stretches", "Ergo Essentials", "Healthy Living", "Yoga Exercise", "Productivity Hacks", "Posture Perfect", ],
        schedule_request_status: 1,
        schedule_request: this.$store.getters["ClientsConfig/scheduleRequest"],
        formlink_self_assessment: "",
        formlink_ergo_eval: "",
        formlink_schedule_request: "",
        formlink_self_assesment_follow_up: "",
        formlink_ergo_eval_follow_up: "",
        site_footer: "",
        email_signature: "",
        banner_image: "",
        logo: "",
        self_assessment_followup: [
          {
            interval: null,
            email_template_id: null,
          },
        ],
        ergo_eval_followup: [
          {
            interval: null,
            email_template_id: null,
          },
        ],
        office_equipment_heading : null,
        home_equipment_heading : null,
        lab_equipment_heading : null,
        wfh_saftey_message : null,
        summary_report_footer : null,
        conditional_msg : 0,
        conditional_msg_content : null,
      },
    };
  },
  beforeMount() {
    if (this.$route.params.id != undefined) {
      this.getEmailTemplates(this.$route.params.id);
      this.buttonUpdate = true;
      this.getSingleClient(this.$route.params.id);
    }
  },
  mounted() {
    document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
    this.base_url = process.env.VUE_APP_API_URL;
    this.getRoles();
    this.getAllRegions();
  },
  methods: {
    toggleColorPicker(e){
      var element = e.target;  
      if(element.id=='primary_color_icon'){
        this.$refs.primary_color_picker.togglePicker();        
      }

      if(element.id=='secondary_color_icon'){
        this.$refs.secondary_color_picker.togglePicker();
      }
    },
    addDomain() {
        this.client_data.domain_seat_count.push({
            domain: "",
            seat_count: 0,
        });
    },
    removeDomain(indx) {
        this.client_data.domain_seat_count.splice(indx, 1);
    },
    addSeflAssessmentFolloup() {
      this.client_data.self_assessment_followup.push({
        interval: null,
        email_template_id: null,
      });
    },
    removeSeflAssessmentFolloup(indx) {
      this.client_data.self_assessment_followup.splice(indx, 1);
    },
    addErgoEvalFolloup() {
      this.client_data.ergo_eval_followup.push({
        interval: null,
        email_template_id: null,
      });
    },
    removeErgoEvalFolloup(indx) {
      this.client_data.ergo_eval_followup.splice(indx, 1);
    },
    onFileChange(e, file_for) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0], file_for);
    },
    createImage(file, file_for) {
      var reader = new FileReader();

      reader.onload = (e) => {
        if (file_for == "banner") {
          this.client_data.banner_image = e.target.result;
        }
        if (file_for == "logo") {
          this.client_data.logo = e.target.result;
        }
        if (file_for == "work_from_office_icon") {
          this.client_data.new_work_from_office_icon = true;
          this.client_data.work_area_details.Office.icon = e.target.result;
        }
        if (file_for == "work_from_home_icon") {
          this.client_data.new_work_from_home_icon = true;
          this.client_data.work_area_details.Home.icon = e.target.result;
        }
        if (file_for == "work_from_anywhere_icon") {
          this.client_data.new_work_from_anywhere_icon = true;
          this.client_data.work_area_details.Anywhere.icon = e.target.result;
        }
        if (file_for == "work_from_lab_icon") {
          this.client_data.new_work_from_lab_icon = true;
          this.client_data.work_area_details.Lab.icon = e.target.result;
        }
        if (file_for == "work_from_travel_icon") {
          this.client_data.new_work_from_travel_icon = true;
          this.client_data.work_area_details.Travel.icon = e.target.result;
        }
      };

      reader.readAsDataURL(file);
    },
    getAllRegions(){
      var _this = this;
      let config = {
        method: "get",
        url: process.env.VUE_APP_API_URL + "/api/get-all-region",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      };
      _this
      .axios(config)
      .then(({ data }) => {
        var decodedJson = _this.decodeAPIResponse(data.data);
        _this.region_list = decodedJson.region_list;
      })
      .catch(({ response }) => {
        console.log(response);
      });
    },
    getRoles() {
      var _this = this;
      let config = {
        method: "get",
        url: process.env.VUE_APP_API_URL + "/api/default-roles",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      };
      _this
        .axios(config)
        .then(({ data }) => {
          var decodedJson = _this.decodeAPIResponse(data.data);
          _this.user_roles = decodedJson.userroles;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    getEmailTemplates(clientid) {
      var _this = this;
      let config = {
        method: "get",
        url: process.env.VUE_APP_API_URL + "/api/get-templates/" + clientid,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      };
      _this
        .axios(config)
        .then(({ data }) => {
          var decodedData = _this.decodeAPIResponse(data.data);
          _this.email_templates = decodedData.templates;
          decodedData.templates.forEach((element) => {
            if (element.type == "Self Assesment Follow Up") {
              _this.sa_email_templates.push({
                id: element.id,
                subject: element.template_name,
              });
            }
            if (element.type == "Ergo Eval Follow Up") {
              _this.ee_email_templates.push({
                id: element.id,
                subject: element.template_name,
              });
            }
          });
        })
        .catch(({response}) => {
          console.log(response);
        });
    },
    addClient() {
      var _this = this;
      if (localStorage.getItem("userToken")) {
        _this.$validator.validateAll("addCientForm").then((isValid) => {
          if (isValid) {
            _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
            var arrNewWorkAreaDetails = {};
            var old_work_area_details = _this.client_data.work_area_details;
            for (let i = 0; i < _this.client_data.work_area.length; i++) {
              arrNewWorkAreaDetails[_this.client_data.work_area[i]] =
                _this.client_data.work_area_details[
                  _this.client_data.work_area[i]
                ];
            }
            _this.client_data.work_area_details = arrNewWorkAreaDetails;

            if (_this.client_data.u_id) {
              let config = {
                method: "put",
                url:
                  process.env.VUE_APP_API_URL +
                  "/api/update-client/" +
                  _this.client_data.u_id,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${window.localStorage.getItem(
                    "userToken"
                  )}`,
                },

                // data: _this.client_data,
                data: {request_data : this.encodeAPIRequestWithImages(_this.client_data)},
              };
              _this
                .axios(config)
                .then(({ data }) => {
                  _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                  _this.$toast.success(data.message, {
                    position: "top-right",
                    duration: 5000,
                  });
                  
                  _this.buttonUpdate = false;
                  /*let auth_data = this.$store.getters["auth/authUser"];
                  let company_data = auth_data.client;

                  if(company_data.u_id == _this.client_data.u_id){
                    window.localStorage.removeItem("companyInformation");
                    window.localStorage.setItem("companyInformation", JSON.stringify(_this.client_data));
                    setTimeout(() => {
                      window.location.href = window.location.origin+'/admin/all_clients';
                    },2000);
                  } else {*/
                    _this.$router.push({
                      name: "all_clients",
                    });
                  // }
                })
                .catch((err) => {
                  _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                  if (err.response.status == 422) {
                    var err1 = "";
                    for (let x in err.response.data.error) {
                      err1 += err.response.data.error[x];
                      err1 += "<br>";
                    }
                    _this.$toast.error(err1, {
                      position: "top-right",
                      duration: 5000,
                    });
                  } else if (err.response.status == 401) {
                    _this.tokenExpired();
                  }
                  else {
                    _this.$toast.error(err.response.data.error, {
                      position: "top-right",
                      duration: 5000,
                    });
                  }
                });
            } else {
              let config = {
                method: "post",
                url: process.env.VUE_APP_API_URL + "/api/client/create",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${window.localStorage.getItem(
                    "userToken"
                  )}`,
                },

                data: {request_data : this.encodeAPIRequestWithImages(_this.client_data)},
              };
              _this
                .axios(config)
                .then(({ data }) => {
                  _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                  _this.$toast.success(data.message, {
                    position: "top-right",
                    duration: 5000,
                  });
                  _this.$router.push({
                    name: "all_clients",
                  });
                })
                .catch(({ response }) => {
                  _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                  if (response.status == 422) {
                    var err = "";
                    for (let x in response.data.error) {
                      err += response.data.error[x];
                      err += "<br>";
                    }
                    _this.$toast.error(err, {
                      position: "top-right",
                      duration: 5000,
                    });
                  } else if (response.status == 401) {
                    _this.tokenExpired();
                  } else {
                    _this.$toast.error(response.data.error, {
                      position: "top-right",
                      duration: 5000,
                    });
                  }
                });
            }
            _this.client_data.work_area_details = old_work_area_details;
          } else {
            console.log("Error In Validation", _this.errors);
            const nodeList = document.querySelectorAll(".error");
            nodeList.forEach((element) => {
              if(element.style.display!= 'none'){
                var acc = element.closest('.accordion-collapse');
                if(acc != null)
                  acc.classList.add("show");
              }
            });
          }
        });
      } else {
        _this.tokenExpired();
      }
    },
    getSingleClient(id) {
      var _this = this;
      let config = {
        method: "get",
        url: process.env.VUE_APP_API_URL + "/api/edit-client/" + id,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      };
      _this
        .axios(config)
        .then(({ data }) => {
          if (data.success) {
            var decodedData = _this.decodeAPIResponse(data.data);
            _this.client_data.id = this.$route.params.id;
            _this.client_data.u_id = this.$route.params.id;
            _this.client_data.company_name = decodedData.records.company_name;
            _this.client_data.company_code = decodedData.records.company_code;
            _this.client_data.enable_reminder = decodedData.records.enable_reminder;
            _this.client_data.client_type = decodedData.records.client_type.toString();
            _this.client_data.status = decodedData.records.status;
            _this.client_data.report_type = decodedData.records.report_type;
            _this.client_data.hex_color = decodedData.records.hex_color;
            _this.client_data.secondary_hex_color = decodedData.records.secondary_hex_color;
            this.primary_color = this.client_data.hex_color;
            this.secondary_color = this.client_data.secondary_hex_color;
            this.color_picker_reload++;
            _this.client_data.billing_cost = decodedData.records.billing_cost;
            _this.client_data.region = JSON.parse(decodedData.records.region);
            _this.client_data.default_user_role = parseInt(decodedData.records.default_user_role);
            _this.client_data.total_seats = decodedData.records.total_seats;
            _this.client_data.total_seats_used = decodedData.records.total_seats_used;
            _this.client_data.manage_self_assessment_btn = decodedData.records.self_assessment_button_status.toString();
            _this.client_data.manage_followup_status = decodedData.records.followup_status.toString();

            if (decodedData.records.domains.length) {_this.client_data.domain_seat_count = decodedData.records.domains;} else {
              _this.client_data.domain_seat_count = [
                {
                  domain: "",
                  seat_count: 0,
                },
              ];
            }
            _this.client_data.work_area = decodedData.records.work_area;
            _this.client_data.work_from_office = decodedData.records.work_from_office;
            _this.client_data.work_from_home = decodedData.records.work_from_home;
            _this.client_data.work_from_anywhere =
              decodedData.records.work_from_anywhere;
            _this.client_data.work_from_lab = decodedData.records.work_from_lab;
            _this.client_data.work_from_travel = decodedData.records.work_from_travel;
            if (decodedData.records.work_area.length && decodedData.records.work_area_details != undefined) {
              if (decodedData.records.work_area_details.Office != undefined) {
                _this.client_data.work_area_details.Office = decodedData.records.work_area_details.Office;
                _this.client_data.work_area_details.Office.oldIcon = decodedData.records.work_area_details.Office.icon;
              }
              if (decodedData.records.work_area_details.Home != undefined) {
                _this.client_data.work_area_details.Home = decodedData.records.work_area_details.Home;
                _this.client_data.work_area_details.Home.oldIcon = decodedData.records.work_area_details.Home.icon;
              }
              if (decodedData.records.work_area_details.Anywhere != undefined) {
                _this.client_data.work_area_details.Anywhere = decodedData.records.work_area_details.Anywhere;
                _this.client_data.work_area_details.Anywhere.oldIcon = decodedData.records.work_area_details.Anywhere.icon;
              }
              if (decodedData.records.work_area_details.Lab != undefined) {
                _this.client_data.work_area_details.Lab = decodedData.records.work_area_details.Lab;
                _this.client_data.work_area_details.Lab.oldIcon = decodedData.records.work_area_details.Lab.icon;
              }
              if (decodedData.records.work_area_details.Travel != undefined) {
                _this.client_data.work_area_details.Travel = decodedData.records.work_area_details.Travel;
                _this.client_data.work_area_details.Travel.oldIcon = decodedData.records.work_area_details.Travel.icon;
              }
            }
            if (decodedData.records.site_menu_details != undefined) {
              if (decodedData.records.site_menu_details.Home != undefined) {
                _this.client_data.site_menu_details.Home = decodedData.records.site_menu_details.Home;
              }
              if (decodedData.records.site_menu_details.WorkBetter != undefined) {
                _this.client_data.site_menu_details.WorkBetter = decodedData.records.site_menu_details.WorkBetter;
              }
              if (decodedData.records.site_menu_details.SetupGuidelines != undefined) {
                _this.client_data.site_menu_details.SetupGuidelines = decodedData.records.site_menu_details.SetupGuidelines;
              }
              if (decodedData.records.site_menu_details.Videos != undefined) {
                _this.client_data.site_menu_details.Videos = decodedData.records.site_menu_details.Videos;
              }
              if (decodedData.records.site_menu_details.ErgoEssentials != undefined) {
                _this.client_data.site_menu_details.ErgoEssentials = decodedData.records.site_menu_details.ErgoEssentials;
              }
            }
            if(decodedData.records.help_center_details != undefined){
              _this.client_data.help_center_details = decodedData.records.help_center_details;
            }
            _this.client_data.video_playlist = decodedData.records.video_playlist;
            _this.client_data.schedule_request_status = decodedData.records.schedule_request_status;
            _this.client_data.schedule_request = decodedData.records.schedule_request;
            _this.client_data.formlink_self_assessment = decodedData.records.formlink_self_assessment;
            _this.client_data.formlink_ergo_eval = decodedData.records.formlink_ergo_eval;
            _this.client_data.formlink_schedule_request = decodedData.records.formlink_schedule_request;
            _this.client_data.formlink_self_assesment_follow_up = decodedData.records.formlink_self_assesment_follow_up;
            _this.client_data.formlink_ergo_eval_follow_up = decodedData.records.formlink_ergo_eval_follow_up;
            _this.client_data.site_footer = decodedData.records.site_footer;
            _this.client_data.email_signature = decodedData.records.email_signature;
            _this.client_data.banner_image = decodedData.records.banner_image;
            _this.client_data.logo = decodedData.records.logo;
            if (decodedData.records.self_assessment_followup && decodedData.records.self_assessment_followup.length
            ) {
              _this.client_data.self_assessment_followup = decodedData.records.self_assessment_followup;
            } else {
              _this.client_data.self_assessment_followup = [
                {
                  interval: null,
                  email_template_id: null,
                },
              ];
            }
            if (decodedData.records.ergo_eval_followup && decodedData.records.ergo_eval_followup.length ) {
              _this.client_data.ergo_eval_followup = decodedData.records.ergo_eval_followup;
            } else {
              _this.client_data.ergo_eval_followup = [
                {
                  interval: null,
                  email_template_id: null,
                },
              ];
            }
            _this.client_data.office_equipment_heading = decodedData.records.office_equipment_heading!=null ? decodedData.records.office_equipment_heading : JSON.parse(JSON.stringify(this.summaryReportInfo.office_equipment_heading)) ;   
                     
            _this.client_data.home_equipment_heading = decodedData.records.home_equipment_heading!=null ? decodedData.records.home_equipment_heading : JSON.parse(JSON.stringify(this.summaryReportInfo.home_equipment_heading));
            
            _this.client_data.lab_equipment_heading = decodedData.records.lab_equipment_heading!=null ? decodedData.records.lab_equipment_heading : JSON.parse(JSON.stringify(this.summaryReportInfo.lab_equipment_heading)) ;

            _this.client_data.wfh_saftey_message = decodedData.records.wfh_saftey_message!=null ? decodedData.records.wfh_saftey_message : JSON.parse(JSON.stringify(this.summaryReportInfo.wfh_saftey_message));

            _this.client_data.summary_report_footer = decodedData.records.summary_report_footer!=null ? decodedData.records.summary_report_footer : JSON.parse(JSON.stringify(this.summaryReportInfo.summary_report_footer));

            _this.client_data.conditional_msg = decodedData.records.conditional_msg!=null ? decodedData.records.conditional_msg : JSON.parse(JSON.stringify(this.summaryReportInfo.conditional_msg));
            
            _this.client_data.conditional_msg_content = decodedData.records.conditional_msg_content!=null ? decodedData.records.conditional_msg_content : JSON.parse(JSON.stringify(this.summaryReportInfo.conditional_msg_content));

          } else {
            _this.$toast.error(data.message, {
              position: "top-right",
              duration: 5000,
            });
            _this.$router.push({
              name: "all_clients",
            });
          }
        })
        .catch(( response ) => {
          _this.$router.push({
            name: "all_clients",
            params: { errorMsg: response.data.error },
          });
        });
    },
    async toggleHeaderMenu(type, status) {     
            if (status == 1) {         
                this.client_data.site_menu_details[type].status = status;
            } else {
                const confirmComplete = await this.$refs.confirmDialogue.show({
                    title: "",
                    message: "Are you sure you want to hide  ?",
                    okButton: "Yes",
                });
                // On Confirm
                if (confirmComplete) {
                    this.client_data.site_menu_details[type].status = status;
                } else {
                    let el = this.$refs[type+'_show'];
                    el.click();
                }
            }      
        },
  },
};
</script>