<template>
  <div>
    <div class="card site_footer mb_50">
      <div class="card_heading">
        <h4 class="heading2">
          Help Center
          <span v-tooltip="{ ref: 'tooltipRef' }">

            <svg xmlns="http://www.w3.org/2000/svg" data-name="Group 13109" width="16" height="16"
              viewBox="0 0 14 18.346">
              <path data-name="Path 9426" d="M0 0h14v14H0z" style="fill: none"></path>
              <path data-name="Path 9427"
                d="M9 16a7 7 0 1 1 7-7 7 7 0 0 1-7 7zm0-1.4A5.6 5.6 0 1 0 3.4 9 5.6 5.6 0 0 0 9 14.6zm-.7-9.1h1.4v1.4H8.3zm0 2.8h1.4v4.2H8.3z"
                transform="translate(-2 -1.654)" style="fill: rgb(109, 109, 109)"></path>
            </svg>
            <span>
            </span>
          </span>
        </h4>
      </div>
      <div ref="tooltipRef" class="tooltip-content">
        <p>1. Show/Hide and Update your categories.</p>
        <p>2. Add/Remove and Update your questions as per the categories.</p>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="group_header">
            <label class="hearder_menu_label" for="">General Ergo</label>
            <div class="form-group menu_cont">
              <input type="text" placeholder="General Ergo" name="GeneralErgoHeading" id="GeneralErgoHeading"
                class="form-control" v-model="help_center_details.GeneralErgo.heading" v-validate="'required|max:25'"
                data-vv-as="heading" />
              <div class="tab_report toggle_switch reported for_btn_cont">
                <ul id="myTab1" class="nav nav-tabs">
                  <li class="nav-item">
                    <button id="GeneralErgo_show" ref="GeneralErgo_show" data-bs-toggle="tab" type="button"
                      aria-controls="percent" aria-selected="true"
                      :class="help_center_details.GeneralErgo.status == 1 ? 'nav-link active' : 'nav-link'"
                      @click="toggleHelpCenter('GeneralErgo', 1)">
                      Show
                    </button>
                  </li>
                  <li class="nav-item">
                    <button id="GeneralErgo_hide" ref="GeneralErgo_hide" data-bs-toggle="tab" type="button"
                      aria-controls="hash" aria-selected="false"
                      :class="help_center_details.GeneralErgo.status == 0 ? 'nav-link active' : 'nav-link'"
                      @click="toggleHelpCenter('GeneralErgo', 0)">
                      Hide
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <small class="error text-danger" v-show="errors.has('addCientForm.GeneralErgoHeading')">
              {{ errors.first("addCientForm.GeneralErgoHeading") }}
            </small>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="group_header">
            <label class="hearder_menu_label" for="">My Reports</label>
            <div class="form-group menu_cont">
              <input type="text" placeholder="My Reports" name="MyReportsHeading" id="MyReportsHeading"
                class="form-control" v-model="help_center_details.MyReports.heading" v-validate="'required|max:25'"
                data-vv-as="heading" />
              <div class="tab_report toggle_switch reported for_btn_cont">
                <ul id="myTab1" class="nav nav-tabs">
                  <li class="nav-item">
                    <button id="MyReports_show" ref="MyReports_show" data-bs-toggle="tab" type="button"
                      aria-controls="percent" aria-selected="true"
                      :class="help_center_details.MyReports.status == 1 ? 'nav-link active' : 'nav-link'"
                      @click="toggleHelpCenter('MyReports', 1)">
                      Show
                    </button>
                  </li>
                  <li class="nav-item">
                    <button id="hash-tab1" data-bs-toggle="tab" type="button" aria-controls="hash" aria-selected="false"
                      :class="help_center_details.MyReports.status == 0 ? 'nav-link active' : 'nav-link'"
                      @click="toggleHelpCenter('MyReports', 0)">
                      Hide
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <small class="error text-danger" v-show="errors.has('addCientForm.MyReportsHeading')">
              {{ errors.first("addCientForm.MyReportsHeading") }}
            </small>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="group_header">
            <label class="hearder_menu_label" for="">My Data</label>
            <div class="form-group menu_cont">
              <input type="text" placeholder="My Data" name="MyDataHeading" id="MyDataHeading" class="form-control"
                v-model="help_center_details.MyData.heading" v-validate="'required|max:25'" data-vv-as="heading" />
              <div class="tab_report toggle_switch reported for_btn_cont">
                <ul id="myTab1" class="nav nav-tabs">
                  <li class="nav-item">
                    <button id="MyData_show" ref="MyData_show" data-bs-toggle="tab" type="button" aria-controls="percent"
                      aria-selected="true"
                      :class="help_center_details.MyData.status == 1 ? 'nav-link active' : 'nav-link'"
                      @click="toggleHelpCenter('MyData', 1)">
                      Show
                    </button>
                  </li>
                  <li class="nav-item">
                    <button id="hash-tab1" data-bs-toggle="tab" type="button" aria-controls="hash" aria-selected="false"
                      :class="help_center_details.MyData.status == 0 ? 'nav-link active' : 'nav-link'"
                      @click="toggleHelpCenter('MyData', 0)">
                      Hide
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <small class="error text-danger" v-show="errors.has('addCientForm.MyDataHeading')">
              {{ errors.first("addCientForm.MyDataHeading") }}
            </small>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="group_header">
            <label class="hearder_menu_label" for="">Additional Support</label>
            <div class="form-group menu_cont">
              <input type="text" placeholder="Additional Support" name="AdditionalSupportHeading"
                id="AdditionalSupportHeading" class="form-control" v-model="help_center_details.AdditionalSupport.heading"
                v-validate="'required|max:25'" data-vv-as="heading" />
              <div class="tab_report toggle_switch reported for_btn_cont">
                <ul id="myTab1" class="nav nav-tabs">
                  <li class="nav-item">
                    <button id="AdditionalSupport_show" ref="AdditionalSupport_show" data-bs-toggle="tab" type="button"
                      aria-controls="percent" aria-selected="true"
                      :class="help_center_details.AdditionalSupport.status == 1 ? 'nav-link active' : 'nav-link'"
                      @click="toggleHelpCenter('AdditionalSupport', 1)">
                      Show
                    </button>
                  </li>
                  <li class="nav-item">
                    <button id="hash-tab1" data-bs-toggle="tab" type="button" aria-controls="hash" aria-selected="false"
                      :class="help_center_details.AdditionalSupport.status == 0 ? 'nav-link active' : 'nav-link'"
                      @click="toggleHelpCenter('AdditionalSupport', 0)">
                      Hide
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <small class="error text-danger" v-show="errors.has('addCientForm.AdditionalSupportHeading')">
              {{ errors.first("addCientForm.AdditionalSupportHeading") }}
            </small>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="group_header">
            <label class="hearder_menu_label" for="">Dashboard Tutorials</label>
            <div class="form-group menu_cont">
              <input type="text" placeholder="Dashboard Tutorials" name="DashboardTutorialsHeading"
                id="DashboardTutorialsHeading" class="form-control"
                v-model="help_center_details.DashboardTutorials.heading" v-validate="'required|max:25'"
                data-vv-as="heading" />
              <div class="tab_report toggle_switch reported for_btn_cont">
                <ul id="myTab1" class="nav nav-tabs">
                  <li class="nav-item">
                    <button id="DashboardTutorials_show" ref="DashboardTutorials_show" data-bs-toggle="tab" type="button"
                      aria-controls="percent" aria-selected="true"
                      :class="help_center_details.DashboardTutorials.status == 1 ? 'nav-link active' : 'nav-link'"
                      @click="toggleHelpCenter('DashboardTutorials', 1)">
                      Show
                    </button>
                  </li>
                  <li class="nav-item">
                    <button id="hash-tab1" data-bs-toggle="tab" type="button" aria-controls="hash" aria-selected="false"
                      :class="help_center_details.DashboardTutorials.status == 0 ? 'nav-link active' : 'nav-link'"
                      @click="toggleHelpCenter('DashboardTutorials', 0)">
                      Hide
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <small class="error text-danger" v-show="errors.has('addCientForm.DashboardTutorialsHeading')">
              {{ errors.first("addCientForm.DashboardTutorialsHeading") }}
            </small>
          </div>
        </div>
      </div>
    </div>
    <div id="accordionPanelsStayOpenExamplesHelp">
      <div class="accordion-item">
        <div v-show="help_center_details.DashboardTutorials.status" class="card site_footer mb_50">
          <div class="card_heading">
            <h4 class="heading2 accordion-header" id="panelsStayOpen-headingTwelve">
              <button class="accordion-button" type="button" data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwelve" aria-expanded="false"
                aria-controls="panelsStayOpen-collapseTwelve">
                Dashboard Tutorials
                <div class="down_up_arrow">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" role="presentation"
                    class="vs__open-indicator">
                    <path
                      d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z">
                    </path>
                  </svg>
                </div>
              </button>
            </h4>
          </div>
          <div id="panelsStayOpen-collapseTwelve" class="accordion-collapse collapse marg_top show"
            aria-labelledby="panelsStayOpen-headingTwelve">
            <div class="accordion-body bdr_bottom" v-for="(value, index) in help_center_details.DashboardTutorials.data"
              :key="index">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="" class="form-label">Question</label>
                    <input type="text" placeholder="Question" class="form-control" v-model="value.question"
                      :id="'DashboardTutorials_que_' + index" :name="'DashboardTutorials_que_' + index"
                      v-validate="help_center_details.DashboardTutorials.status ? 'required|max:150' : ''"
                      data-vv-as="question" />
                    <small class="error text-danger" :id="'DashboardTutorials_que_error' + index"
                      v-show="errors.has('addCientForm.DashboardTutorials_que_' + index)">
                      {{ errors.first("addCientForm.DashboardTutorials_que_" + index)
                      }}
                    </small>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group select_image cont_img">
                    <label class="form-label">Icon<svg style="margin-left :5px;"
                        v-tooltip='"You can upload png/svg/jpg file upto 1 mb and has dimensions upto 40 X 40 pixels"'
                        xmlns="http://www.w3.org/2000/svg" data-name="Group 13109" width="16" height="16"
                        viewBox="0 0 14 18.346">
                        <path data-name="Path 9426" d="M0 0h14v14H0z" style="fill: none"></path>
                        <path data-name="Path 9427"
                          d="M9 16a7 7 0 1 1 7-7 7 7 0 0 1-7 7zm0-1.4A5.6 5.6 0 1 0 3.4 9 5.6 5.6 0 0 0 9 14.6zm-.7-9.1h1.4v1.4H8.3zm0 2.8h1.4v4.2H8.3z"
                          transform="translate(-2 -1.654)" style="fill: rgb(109, 109, 109)"></path>
                      </svg></label>
                    <div class="input-group align-items-center">
                      <input type="file" class="form-control" :id="'DashboardTutorials_icon_' + index"
                        @change="createHelpCenterIcon($event, 'DashboardTutorials', index)"
                        @click="initializeFile($event, 'DashboardTutorials', index)" v-validate="help_center_details.DashboardTutorials.status
                          ? 'ext:svg,jpg,png,jpeg|maxdimensions:40,40|size:1024'
                          : ''
                          " :name="'DashboardTutorials_icon_' + index" data-vv-as="icon"
                        :ref="'DashboardTutorials_icon_ref' + index" />
                      <div class="img_and_cross removegrey">
                        <img v-show="clientId" :src="!value.icon.includes('data:image') && value.icon != ''
                          ? value.icon &&
                            !value.newIcon
                            ? base_url +
                            '/public/client/images/help_center/' +
                            value.icon
                            : require('@/assets/images/homepage/error.png')
                          : value.icon
                          " style="width: 60px; margin-top: 5px" />
                        <img v-show="!clientId" :src="value.icon
                          ? value.icon.includes('data:image')
                            ? value.icon
                            : require('@/assets/images/homepage/error.png')
                          : ''
                          " style="width: 60px; margin-top: 5px" />
                        <div class="drop_x_img " v-if="value.newIcon">
                          <a href="javascript:void(0);" @click="
                            removeHelpCenterIcon('DashboardTutorials', index)
                            ">X</a>
                        </div>
                      </div>
                    </div>
                    <small :id="'DashboardTutorials_icon_error_' + index" class="error text-danger ms-0 ms-sm-3" v-show="errors.has('addCientForm.DashboardTutorials_icon_' + index)
                      ">
                      {{
                        errors.first(
                          "addCientForm.DashboardTutorials_icon_" + index
                        )
                      }}
                    </small>
                  </div>
                </div>
              </div>
              <div class="row form-group">
                <label for="description" class="form-label"> Answer </label>
                <div class="col-12">
                  <ckeditor v-model="value.answer" :editor="editor" :config="editorConfig"
                    :id="'DashboardTutorials_ans_' + index" :name="'DashboardTutorials_ans_' + index" class="form-control"
                    placeholder="Answer" v-validate="help_center_details.DashboardTutorials.status ? 'required' : ''"
                    data-vv-as="answer" @blur="onLostFocusEditor('addCientForm.DashboardTutorials_ans_' + index)">
                  </ckeditor>
                  <small :id="'DashboardTutorials_ans_error_' + index" class="error text-danger" v-show="errors.has('addCientForm.DashboardTutorials_ans_' + index)
                    ">
                    {{
                      errors.first(
                        "addCientForm.DashboardTutorials_ans_" + index
                      )
                    }}
                  </small>
                </div>
              </div>
              <div class="row vimeovdolink" v-for="(vimeoData, vimeoIndex) in value.vimeoVideos" :key="vimeoIndex">
                <div class="col-lg-5">
                  <div class="form-group">
                    <label for="" class="form-label">Link Title</label>
                    <input type="text" placeholder="Link Title" class="form-control" v-model="vimeoData.linkTitle"
                      :id="'DashboardTutorials_linktitle_'+ index+'_' + vimeoIndex" :name="'DashboardTutorials_linktitle_'+ index+'_' + vimeoIndex"
                      v-validate="help_center_details.DashboardTutorials.status ? 'required|max:40' : ''"
                      data-vv-as="Link Title" />
                    <small class="error text-danger" :id="'DashboardTutorials_linktitle_error'+ index+'_' + vimeoIndex"
                      v-show="errors.has('addCientForm.DashboardTutorials_linktitle_'+ index+'_' + vimeoIndex)">
                      {{ errors.first("addCientForm.DashboardTutorials_linktitle_"+ index+'_' + vimeoIndex)
                      }}
                    </small>
                  </div>
                </div>
                <div class="col-lg-5">
                  <div class="form-group">
                    <label for="" class="form-label">Link URL</label>
                    <input type="text" ref="linkurlref" placeholder="Link URL" class="form-control" v-model="vimeoData.linkUrl"
                      :id="'DashboardTutorials_linkurl_'+ index+'_' + vimeoIndex" :name="'DashboardTutorials_linkurl_'+ index+'_' + vimeoIndex"
                      v-validate="help_center_details.DashboardTutorials.status ? 'validVimeoUrl|required|max:150' : ''"
                      data-vv-as="Link Url" />
                    <small class="error text-danger" :id="'DashboardTutorials_linkurl_error'+ index+'_' + vimeoIndex"
                      v-show="errors.has('addCientForm.DashboardTutorials_linkurl_'+ index+'_' + vimeoIndex)">
                      {{ errors.first("addCientForm.DashboardTutorials_linkurl_"+ index+'_' + vimeoIndex)
                      }}
                    </small>
                  </div>
                </div>
                <div class="col-lg-2 addremovelink">
                  <a class="delete_button" type="button" v-show="vimeoIndex < value.vimeoVideos.length &&
                  value.vimeoVideos.length > 1
                  " @click="removeVimeoHelpCenter('DashboardTutorials', index, vimeoIndex)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="13.503" height="14.94" viewBox="0 0 13.503 14.94">
                    <path data-name="Path 108"
                      d="M14.784 5.876 14.16 14.6a1.438 1.438 0 0 1-1.434 1.336h-5.95A1.438 1.438 0 0 1 5.342 14.6l-.623-8.724m3.594 2.875v4.313m2.876-4.313v4.313m.719-7.189V3.719A.719.719 0 0 0 11.189 3H8.313a.719.719 0 0 0-.719.719v2.157M4 5.876h11.5"
                      transform="translate(-3 -2)" style="
                        fill: none;
                        stroke: #cccccc;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-width: 2px;
                      " />
                  </svg>
                </a>
                <a class="add_button" @click="addVimeoHelpCenter('DashboardTutorials', index)"
                  v-show="vimeoIndex == value.vimeoVideos.length - 1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16.865" height="16.865" viewBox="0 0 16.865 16.865">
                    <path data-name="Icon ionic-md-add"
                      d="M23.615 16.307h-7.308v7.308h-2.249v-7.308H6.75v-2.249h7.308V6.75h2.249v7.308h7.308z"
                      transform="translate(-6.75 -6.75)" />
                  </svg>
                </a>
                </div>
              </div>
              <div class="del d-flex justify-content-between pt_30 pb_20">
                <a class="delete_button" type="button" v-show="index < help_center_details.DashboardTutorials.data.length &&
                  help_center_details.DashboardTutorials.data.length > 1
                  " @click="removeHelpCenter('DashboardTutorials', index)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="13.503" height="14.94" viewBox="0 0 13.503 14.94">
                    <path data-name="Path 108"
                      d="M14.784 5.876 14.16 14.6a1.438 1.438 0 0 1-1.434 1.336h-5.95A1.438 1.438 0 0 1 5.342 14.6l-.623-8.724m3.594 2.875v4.313m2.876-4.313v4.313m.719-7.189V3.719A.719.719 0 0 0 11.189 3H8.313a.719.719 0 0 0-.719.719v2.157M4 5.876h11.5"
                      transform="translate(-3 -2)" style="
                        fill: none;
                        stroke: #cccccc;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-width: 2px;
                      " />
                  </svg>
                </a>
                <a class="add_button" @click="addHelpCenter('DashboardTutorials')"
                  v-show="index == help_center_details.DashboardTutorials.data.length - 1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16.865" height="16.865" viewBox="0 0 16.865 16.865">
                    <path data-name="Icon ionic-md-add"
                      d="M23.615 16.307h-7.308v7.308h-2.249v-7.308H6.75v-2.249h7.308V6.75h2.249v7.308h7.308z"
                      transform="translate(-6.75 -6.75)" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <div v-show="help_center_details.GeneralErgo.status" class="card site_footer mb_50">
          <div class="card_heading">
            <h4 class="heading2 accordion-header" id="panelsStayOpen-headingnine">
              <button class="accordion-button" type="button" data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapsenine" aria-expanded="false"
                aria-controls="panelsStayOpen-collapsenine">
                General Ergo
                <div class="down_up_arrow">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" role="presentation"
                    class="vs__open-indicator">
                    <path
                      d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z">
                    </path>
                  </svg>
                </div>
              </button>
            </h4>
          </div>
          <div id="panelsStayOpen-collapsenine" class="accordion-collapse collapse marg_top show"
            aria-labelledby="panelsStayOpen-headingnine">
            <div class="accordion-body bdr_bottom" v-for="(value, index) in help_center_details.GeneralErgo.data"
              :key="JSON.stringify(index)">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="" class="form-label">Question</label>
                    <input type="text" placeholder="Question" class="form-control" v-model="value.question"
                      :id="'GeneralErgo_que_' + index" :name="'GeneralErgo_que_' + index"
                      v-validate="help_center_details.GeneralErgo.status ? 'required|max:150' : ''" data-vv-as="question"
                      key="GeneralErgoQue-input" />
                    <small class="error text-danger" v-show="errors.has('addCientForm.GeneralErgo_que_' + index)"
                      :id="'GeneralErgo_que_error' + index">
                      {{
                        errors.first("addCientForm.GeneralErgo_que_" + index)
                      }}
                    </small>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group select_image cont_img">
                    <label class="form-label">Icon<svg style="margin-left: 5px;"
                        v-tooltip='"You can upload png/svg/jpg file upto 1 mb and has dimensions upto 40 X 40 pixels"'
                        xmlns="http://www.w3.org/2000/svg" data-name="Group 13109" width="16" height="16"
                        viewBox="0 0 14 18.346">
                        <path data-name="Path 9426" d="M0 0h14v14H0z" style="fill: none"></path>
                        <path data-name="Path 9427"
                          d="M9 16a7 7 0 1 1 7-7 7 7 0 0 1-7 7zm0-1.4A5.6 5.6 0 1 0 3.4 9 5.6 5.6 0 0 0 9 14.6zm-.7-9.1h1.4v1.4H8.3zm0 2.8h1.4v4.2H8.3z"
                          transform="translate(-2 -1.654)" style="fill: rgb(109, 109, 109)"></path>
                      </svg></label>
                    <div class="input-group align-items-center">
                      <input type="file" class="form-control" :id="'GeneralErgo_icon_' + index" @change="
                        createHelpCenterIcon(
                          $event,
                          'GeneralErgo',
                          index
                        )
                        " @click="initializeFile($event, 'GeneralErgo', index)" v-validate="help_center_details.GeneralErgo.status
    ? 'ext:svg,jpg,png,jpeg|maxdimensions:40,40|size:1024'
    : ''
    " :name="'GeneralErgo_icon_' + index" data-vv-as="icon" :ref="'GeneralErgo_icon_ref' + index" />
                      <div class="img_and_cross removegrey">
                        <img v-show="clientId" :src="!value.icon.includes('data:image') && value.icon != '' ? value.icon && !value.newIcon ? base_url + '/public/client/images/help_center/' + value.icon : require('@/assets/images/homepage/error.png') : value.icon
                          " style="width: 60px; margin-top: 5px" />
                        <img v-show="!clientId" :src="value.icon
                          ? value.icon.includes('data:image')
                            ? value.icon
                            : require('@/assets/images/homepage/error.png')
                          : ''
                          " style="width: 60px; margin-top: 5px" />
                        <div class="drop_x_img" v-if="value.newIcon">
                          <a href="javascript:void(0);" @click="removeHelpCenterIcon('GeneralErgo', index)">X</a>
                        </div>
                      </div>
                    </div>

                    <small :id="'GeneralErgo_icon_error_' + index" class="error text-danger ms-0 ms-sm-3"
                      v-show="errors.has('addCientForm.GeneralErgo_icon_' + index)">{{
                        errors.first("addCientForm.GeneralErgo_icon_"
                          + index) }}</small>
                  </div>
                </div>
              </div>
              <div class="row form-group">
                <label for="description" class="form-label"> Answer </label>
                <div class="col-12">
                  <ckeditor v-model="value.answer" :editor="editor" :config="editorConfig"
                    :id="'GeneralErgo_ans_' + index" :name="'GeneralErgo_ans_' + index" class="form-control"
                    placeholder="Answer" v-validate="help_center_details.GeneralErgo.status ? 'required' : ''"
                    data-vv-as="answer" @blur="onLostFocusEditor('addCientForm.GeneralErgo_ans_' + index)"></ckeditor>
                  <small :id="'GeneralErgo_ans_error_' + index" class="error text-danger" v-show="errors.has('addCientForm.GeneralErgo_ans_' + index)
                    ">
                    {{ errors.first("addCientForm.GeneralErgo_ans_" + index) }}
                  </small>
                </div>
              </div>
              <div class="del d-flex justify-content-between">
                <a class="delete_button" type="button" v-show="index < help_center_details.GeneralErgo.data.length &&
                  help_center_details.GeneralErgo.data.length > 1
                  " @click="removeHelpCenter('GeneralErgo', index)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="13.503" height="14.94" viewBox="0 0 13.503 14.94">
                    <path data-name="Path 108"
                      d="M14.784 5.876 14.16 14.6a1.438 1.438 0 0 1-1.434 1.336h-5.95A1.438 1.438 0 0 1 5.342 14.6l-.623-8.724m3.594 2.875v4.313m2.876-4.313v4.313m.719-7.189V3.719A.719.719 0 0 0 11.189 3H8.313a.719.719 0 0 0-.719.719v2.157M4 5.876h11.5"
                      transform="translate(-3 -2)" style="
                        fill: none;
                        stroke: #cccccc;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-width: 2px;
                      " />
                  </svg>
                </a>
                <a class="add_button" @click="addHelpCenter('GeneralErgo')"
                  v-show="index == help_center_details.GeneralErgo.data.length - 1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16.865" height="16.865" viewBox="0 0 16.865 16.865">
                    <path data-name="Icon ionic-md-add"
                      d="M23.615 16.307h-7.308v7.308h-2.249v-7.308H6.75v-2.249h7.308V6.75h2.249v7.308h7.308z"
                      transform="translate(-6.75 -6.75)" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <div v-show="help_center_details.MyReports.status" class="card site_footer mb_50">
          <div class="card_heading">
            <h4 class="heading2 accordion-header" id="panelsStayOpen-headingTen">
              <button class="accordion-button" type="button" data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTen" aria-expanded="false"
                aria-controls="panelsStayOpen-collapseTen">
                My Reports
                <div class="down_up_arrow">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" role="presentation"
                    class="vs__open-indicator">
                    <path
                      d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z">
                    </path>
                  </svg>
                </div>
              </button>
            </h4>
          </div>
          <div id="panelsStayOpen-collapseTen" class="accordion-collapse collapse marg_top show"
            aria-labelledby="panelsStayOpen-headingTen">
            <div class="accordion-body bdr_bottom" v-for="(value, index) in help_center_details.MyReports.data"
              :key="index">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="" class="form-label">Question</label>
                    <input type="text" placeholder="Question" class="form-control" v-model="value.question"
                      :id="'MyReports_que_' + index" :name="'MyReports_que_' + index"
                      v-validate="help_center_details.MyReports.status ? 'required|max:150' : ''" data-vv-as="question" />
                    <small class="error text-danger" :id="'MyReports_que_error' + index"
                      v-show="errors.has('addCientForm.MyReports_que_' + index)">
                      {{ errors.first("addCientForm.MyReports_que_" + index) }}
                    </small>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group select_image cont_img">
                    <label class="form-label">Icon<svg style="margin-left:5px;"
                        v-tooltip='"You can upload png/svg/jpg file upto 1 mb and has dimensions upto 40 X 40 pixels"'
                        xmlns="http://www.w3.org/2000/svg" data-name="Group 13109" width="16" height="16"
                        viewBox="0 0 14 18.346">
                        <path data-name="Path 9426" d="M0 0h14v14H0z" style="fill: none"></path>
                        <path data-name="Path 9427"
                          d="M9 16a7 7 0 1 1 7-7 7 7 0 0 1-7 7zm0-1.4A5.6 5.6 0 1 0 3.4 9 5.6 5.6 0 0 0 9 14.6zm-.7-9.1h1.4v1.4H8.3zm0 2.8h1.4v4.2H8.3z"
                          transform="translate(-2 -1.654)" style="fill: rgb(109, 109, 109)"></path>
                      </svg></label>
                    <div class="input-group align-items-center">
                      <input type="file" class="form-control" :id="'MyReports_icon_' + index" @change="
                        createHelpCenterIcon($event, 'MyReports', index)
                        " @click="initializeFile($event, 'MyReports', index)" v-validate="help_center_details.MyReports.status
    ? 'ext:svg,jpg,png,jpeg|maxdimensions:40,40|size:1024'
    : ''
    " :name="'MyReports_icon_' + index" data-vv-as="icon" :ref="'MyReports_icon_ref' + index" />
                      <div class="img_and_cross removegrey">
                        <img v-show="clientId" :src="!value.icon.includes('data:image') && value.icon != ''
                          ? value.icon &&
                            !value.newIcon
                            ? base_url +
                            '/public/client/images/help_center/' +
                            value.icon
                            : require('@/assets/images/homepage/error.png')
                          : value.icon
                          " style="width: 60px; margin-top: 5px" />
                        <img v-show="!clientId" :src="value.icon
                          ? value.icon.includes('data:image')
                            ? value.icon
                            : require('@/assets/images/homepage/error.png')
                          : ''
                          " style="width: 60px; margin-top: 5px" />
                        <div class="drop_x_img" v-if="value.newIcon">
                          <a href="javascript:void(0);" @click="removeHelpCenterIcon('MyReports', index)">X</a>
                        </div>
                      </div>
                    </div>
                    <small :id="'MyReports_icon_error_' + index" class="error text-danger ms-0 ms-sm-3"
                      v-show="errors.has('addCientForm.MyReports_icon_' + index)">
                      {{
                        errors.first("addCientForm.MyReports_icon_" + index)
                      }}
                    </small>
                  </div>
                </div>
              </div>
              <div class="row form-group">
                <label for="description" class="form-label"> Answer </label>
                <div class="col-12">
                  <ckeditor :editor="editor" v-model="value.answer" :config="editorConfig" :id="'MyReports_ans_' + index"
                    :name="'MyReports_ans_' + index" class="form-control" placeholder="Answer"
                    v-validate="help_center_details.MyReports.status ? 'required' : ''" data-vv-as="answer"
                    @blur="onLostFocusEditor('addCientForm.MyReports_ans_' + index)"></ckeditor>
                  <small :id="'MyReports_ans_error_' + index" class="error text-danger"
                    v-show="errors.has('addCientForm.MyReports_ans_' + index)">
                    {{ errors.first("addCientForm.MyReports_ans_" + index) }}
                  </small>
                </div>
              </div>
              <div class="del d-flex justify-content-between">
                <a class="delete_button" type="button" v-show="index < help_center_details.MyReports.data.length &&
                  help_center_details.MyReports.data.length > 1
                  " @click="removeHelpCenter('MyReports', index)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="13.503" height="14.94" viewBox="0 0 13.503 14.94">
                    <path data-name="Path 108"
                      d="M14.784 5.876 14.16 14.6a1.438 1.438 0 0 1-1.434 1.336h-5.95A1.438 1.438 0 0 1 5.342 14.6l-.623-8.724m3.594 2.875v4.313m2.876-4.313v4.313m.719-7.189V3.719A.719.719 0 0 0 11.189 3H8.313a.719.719 0 0 0-.719.719v2.157M4 5.876h11.5"
                      transform="translate(-3 -2)" style="fill: none;
                        stroke: #cccccc;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-width: 2px;
                      " />
                  </svg>
                </a>
                <a class="add_button" @click="addHelpCenter('MyReports')"
                  v-show="index == help_center_details.MyReports.data.length - 1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16.865" height="16.865" viewBox="0 0 16.865 16.865">
                    <path data-name="Icon ionic-md-add"
                      d="M23.615 16.307h-7.308v7.308h-2.249v-7.308H6.75v-2.249h7.308V6.75h2.249v7.308h7.308z"
                      transform="translate(-6.75 -6.75)" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <div v-show="help_center_details.MyData.status" class="card site_footer mb_50">
          <div class="card_heading">
            <h4 class="heading2 accordion-header" id="panelsStayOpen-headingeleven">
              <button class="accordion-button" type="button" data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseeleven" aria-expanded="false"
                aria-controls="panelsStayOpen-collapseeleven">
                My Data
                <div class="down_up_arrow">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" role="presentation"
                    class="vs__open-indicator">
                    <path
                      d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z">
                    </path>
                  </svg>
                </div>
              </button>
            </h4>
          </div>
          <div id="panelsStayOpen-collapseeleven" class="accordion-collapse collapse marg_top show"
            aria-labelledby="panelsStayOpen-headingeleven">
            <div class="accordion-body bdr_bottom" v-for="(value, index) in help_center_details.MyData.data" :key="index">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="" class="form-label">Question</label>
                    <input type="text" placeholder="Question" class="form-control" v-model="value.question"
                      :id="'MyData_que_' + index" :name="'MyData_que_' + index"
                      v-validate="help_center_details.MyData.status ? 'required|max:150' : ''" data-vv-as="question" />
                    <small :id="'MyData_que_error' + index" class="error text-danger"
                      v-show="errors.has('addCientForm.MyData_que_' + index)">
                      {{ errors.first("addCientForm.MyData_que_" + index) }}
                    </small>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group select_image cont_img">
                    <label class="form-label">Icon<svg style="margin-left :5px;"
                        v-tooltip='"You can upload png/svg/jpg file upto 1 mb and has dimensions upto 40 X 40 pixels"'
                        xmlns="http://www.w3.org/2000/svg" data-name="Group 13109" width="16" height="16"
                        viewBox="0 0 14 18.346">
                        <path data-name="Path 9426" d="M0 0h14v14H0z" style="fill: none"></path>
                        <path data-name="Path 9427"
                          d="M9 16a7 7 0 1 1 7-7 7 7 0 0 1-7 7zm0-1.4A5.6 5.6 0 1 0 3.4 9 5.6 5.6 0 0 0 9 14.6zm-.7-9.1h1.4v1.4H8.3zm0 2.8h1.4v4.2H8.3z"
                          transform="translate(-2 -1.654)" style="fill: rgb(109, 109, 109)"></path>
                      </svg></label>
                    <div class="input-group align-items-center">
                      <input type="file" class="form-control" :id="'MyData_icon_' + index"
                        @change="createHelpCenterIcon($event, 'MyData', index)"
                        @click="initializeFile($event, 'MyData', index)" v-validate="help_center_details.MyData.status
                          ? 'ext:svg,jpg,png,jpeg|maxdimensions:40,40|size:1024'
                          : ''
                          " :name="'MyData_icon_' + index" data-vv-as="icon" :ref="'MyData_icon_ref' + index" />
                      <div class="img_and_cross removegrey">
                        <img v-show="clientId" :src="!value.icon.includes('data:image') && value.icon != ''
                          ? value.icon && !value.newIcon
                            ? base_url +
                            '/public/client/images/help_center/' +
                            value.icon
                            : require('@/assets/images/homepage/error.png')
                          : value.icon
                          " style="width: 60px; margin-top: 5px" />
                        <img v-show="!clientId" :src="value.icon
                          ? value.icon.includes('data:image')
                            ? value.icon
                            : require('@/assets/images/homepage/error.png')
                          : ''
                          " style="width: 60px; margin-top: 5px" />
                        <div class="drop_x_img" v-if="value.newIcon">
                          <a href="javascript:void(0);" @click="removeHelpCenterIcon('MyData', index)">X</a>
                        </div>
                      </div>
                    </div>
                    <small :id="'MyData_icon_error_' + index" class="error text-danger ms-0 ms-sm-3"
                      v-show="errors.has('addCientForm.MyData_icon_' + index)">
                      {{ errors.first("addCientForm.MyData_icon_" + index) }}
                    </small>
                  </div>
                </div>
              </div>
              <div class="row form-group">
                <label for="description" class="form-label"> Answer </label>
                <div class="col-12">
                  <ckeditor :editor="editor" v-model="value.answer" :config="editorConfig" :id="'MyData_ans_' + index"
                    :name="'MyData_ans_' + index" class="form-control" placeholder="Answer"
                    v-validate="help_center_details.MyData.status ? 'required' : ''" data-vv-as="answer"
                    @blur="onLostFocusEditor('addCientForm.MyData_ans_' + index)"></ckeditor>
                  <small :id="'MyData_ans_error_' + index" class="error text-danger"
                    v-show="errors.has('addCientForm.MyData_ans_' + index)">
                    {{ errors.first("addCientForm.MyData_ans_" + index) }}
                  </small>
                </div>
              </div>
              <div class="del d-flex justify-content-between">
                <a class="delete_button" type="button" v-show="index < help_center_details.MyData.data.length &&
                  help_center_details.MyData.data.length > 1
                  " @click="removeHelpCenter('MyData', index)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="13.503" height="14.94" viewBox="0 0 13.503 14.94">
                    <path data-name="Path 108"
                      d="M14.784 5.876 14.16 14.6a1.438 1.438 0 0 1-1.434 1.336h-5.95A1.438 1.438 0 0 1 5.342 14.6l-.623-8.724m3.594 2.875v4.313m2.876-4.313v4.313m.719-7.189V3.719A.719.719 0 0 0 11.189 3H8.313a.719.719 0 0 0-.719.719v2.157M4 5.876h11.5"
                      transform="translate(-3 -2)" style="fill: none;
                        stroke: #cccccc;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-width: 2px;
                      " />
                  </svg>
                </a>
                <a class="add_button" @click="addHelpCenter('MyData')"
                  v-show="index == help_center_details.MyData.data.length - 1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16.865" height="16.865" viewBox="0 0 16.865 16.865">
                    <path data-name="Icon ionic-md-add"
                      d="M23.615 16.307h-7.308v7.308h-2.249v-7.308H6.75v-2.249h7.308V6.75h2.249v7.308h7.308z"
                      transform="translate(-6.75 -6.75)" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <div v-show="help_center_details.AdditionalSupport.status" class="card site_footer mb_50">
          <div class="card_heading">
            <h4 class="heading2 accordion-header" id="panelsStayOpen-headingTwelve">
              <button class="accordion-button" type="button" data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwelve" aria-expanded="false"
                aria-controls="panelsStayOpen-collapseTwelve">
                Additional Support
                <div class="down_up_arrow">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" role="presentation"
                    class="vs__open-indicator">
                    <path
                      d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z">
                    </path>
                  </svg>
                </div>
              </button>
            </h4>
          </div>
          <div id="panelsStayOpen-collapseTwelve" class="accordion-collapse collapse marg_top show"
            aria-labelledby="panelsStayOpen-headingTwelve">
            <div class="accordion-body bdr_bottom" v-for="(value, index) in help_center_details.AdditionalSupport.data"
              :key="index">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="" class="form-label">Question</label>
                    <input type="text" placeholder="Question" class="form-control" v-model="value.question"
                      :id="'AdditionalSupport_que_' + index" :name="'AdditionalSupport_que_' + index"
                      v-validate="help_center_details.AdditionalSupport.status ? 'required|max:150' : ''"
                      data-vv-as="question" />
                    <small class="error text-danger" :id="'AdditionalSupport_que_error' + index"
                      v-show="errors.has('addCientForm.AdditionalSupport_que_' + index)">
                      {{ errors.first("addCientForm.AdditionalSupport_que_" + index)
                      }}
                    </small>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group select_image cont_img">
                    <label class="form-label">Icon<svg style="margin-left :5px;"
                        v-tooltip='"You can upload png/svg/jpg file upto 1 mb and has dimensions upto 40 X 40 pixels"'
                        xmlns="http://www.w3.org/2000/svg" data-name="Group 13109" width="16" height="16"
                        viewBox="0 0 14 18.346">
                        <path data-name="Path 9426" d="M0 0h14v14H0z" style="fill: none"></path>
                        <path data-name="Path 9427"
                          d="M9 16a7 7 0 1 1 7-7 7 7 0 0 1-7 7zm0-1.4A5.6 5.6 0 1 0 3.4 9 5.6 5.6 0 0 0 9 14.6zm-.7-9.1h1.4v1.4H8.3zm0 2.8h1.4v4.2H8.3z"
                          transform="translate(-2 -1.654)" style="fill: rgb(109, 109, 109)"></path>
                      </svg></label>
                    <div class="input-group align-items-center">
                      <input type="file" class="form-control" :id="'AdditionalSupport_icon_' + index"
                        @change="createHelpCenterIcon($event, 'AdditionalSupport', index)"
                        @click="initializeFile($event, 'AdditionalSupport', index)" v-validate="help_center_details.AdditionalSupport.status
                          ? 'ext:svg,jpg,png,jpeg|maxdimensions:40,40|size:1024'
                          : ''
                          " :name="'AdditionalSupport_icon_' + index" data-vv-as="icon"
                        :ref="'AdditionalSupport_icon_ref' + index" />
                      <div class="img_and_cross removegrey">
                        <img v-show="clientId" :src="!value.icon.includes('data:image') && value.icon != ''
                          ? value.icon &&
                            !value.newIcon
                            ? base_url +
                            '/public/client/images/help_center/' +
                            value.icon
                            : require('@/assets/images/homepage/error.png')
                          : value.icon
                          " style="width: 60px; margin-top: 5px" />
                        <img v-show="!clientId" :src="value.icon
                          ? value.icon.includes('data:image')
                            ? value.icon
                            : require('@/assets/images/homepage/error.png')
                          : ''
                          " style="width: 60px; margin-top: 5px" />
                        <div class="drop_x_img " v-if="value.newIcon">
                          <a href="javascript:void(0);" @click="
                            removeHelpCenterIcon('AdditionalSupport', index)
                            ">X</a>
                        </div>
                      </div>
                    </div>
                    <small :id="'AdditionalSupport_icon_error_' + index" class="error text-danger ms-0 ms-sm-3" v-show="errors.has('addCientForm.AdditionalSupport_icon_' + index)
                      ">
                      {{
                        errors.first(
                          "addCientForm.AdditionalSupport_icon_" + index
                        )
                      }}
                    </small>
                  </div>
                </div>
              </div>
              <div class="row form-group">
                <label for="description" class="form-label"> Answer </label>
                <div class="col-12">
                  <ckeditor v-model="value.answer" :editor="editor" :config="editorConfig"
                    :id="'AdditionalSupport_ans_' + index" :name="'AdditionalSupport_ans_' + index" class="form-control"
                    placeholder="Answer" v-validate="help_center_details.AdditionalSupport.status ? 'required' : ''"
                    data-vv-as="answer" @blur="onLostFocusEditor('addCientForm.AdditionalSupport_ans_' + index)">
                  </ckeditor>
                  <small :id="'AdditionalSupport_ans_error_' + index" class="error text-danger" v-show="errors.has('addCientForm.AdditionalSupport_ans_' + index)
                    ">
                    {{
                      errors.first(
                        "addCientForm.AdditionalSupport_ans_" + index
                      )
                    }}
                  </small>
                </div>
              </div>
              <div class="del d-flex justify-content-between">
                <a class="delete_button" type="button" v-show="index < help_center_details.AdditionalSupport.data.length &&
                    help_center_details.AdditionalSupport.data.length > 1
                    " @click="removeHelpCenter('AdditionalSupport', index)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="13.503" height="14.94" viewBox="0 0 13.503 14.94">
                    <path data-name="Path 108"
                      d="M14.784 5.876 14.16 14.6a1.438 1.438 0 0 1-1.434 1.336h-5.95A1.438 1.438 0 0 1 5.342 14.6l-.623-8.724m3.594 2.875v4.313m2.876-4.313v4.313m.719-7.189V3.719A.719.719 0 0 0 11.189 3H8.313a.719.719 0 0 0-.719.719v2.157M4 5.876h11.5"
                      transform="translate(-3 -2)" style="
                        fill: none;
                        stroke: #cccccc;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-width: 2px;
                      " />
                  </svg>
                </a>
                <a class="add_button" @click="addHelpCenter('AdditionalSupport')"
                  v-show="index == help_center_details.AdditionalSupport.data.length - 1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16.865" height="16.865" viewBox="0 0 16.865 16.865">
                    <path data-name="Icon ionic-md-add"
                      d="M23.615 16.307h-7.308v7.308h-2.249v-7.308H6.75v-2.249h7.308V6.75h2.249v7.308h7.308z"
                      transform="translate(-6.75 -6.75)" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import ClassicEditor from "ckeditor5-build-classic-image";
export default {
  inject: ["parentValidator"],
  name: "ClientHelpCenter",
  props: ["help_center_details", "base_url", "clientId"],
  created() {

    this.$validator = this.parentValidator
  },
  data() {
    return {
      helpCenter: this.$store.getters["ClientsConfig/helpCenter"],
      editor: ClassicEditor,
      editorData:
        "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>",
      editorConfig: {
        // The configuration of the editor.
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "mediaEmbed",
            "undo",
            "redo",
            "imageUpload"
          ],
        },
        mediaEmbed: {
          previewsInData: true
        },
      },
    };
  },
  beforeMount() {
    if (this.$route.params.id == undefined) {
      // Json functions are used so that original values doesnt get changed
      this.help_center_details.DashboardTutorials.data = JSON.parse(JSON.stringify(this.helpCenter.DashboardTutorials));
      this.help_center_details.GeneralErgo.data = JSON.parse(JSON.stringify(this.helpCenter.GeneralErgo));
      this.help_center_details.MyReports.data = JSON.parse(JSON.stringify(this.helpCenter.MyReports));
      this.help_center_details.MyData.data = JSON.parse(JSON.stringify(this.helpCenter.MyData));
      this.help_center_details.AdditionalSupport.data = JSON.parse(JSON.stringify(this.helpCenter.AdditionalSupport));
    }
  },
  watch: {
    values() {
      this.$nextTick(() => {
        this.$refs.form.validate();
      });
    }
  },
  methods: {
    onLostFocusEditor(id) {
      this.$parent.onLostFocusEditor(id);
    },
    addHelpCenter(type) {
      this.help_center_details[type].data.push({
        question: "",
        answer: "",
        icon: "",
        oldIcon: "",
        newIcon: false,
        vimeoVideos:[{
          linkTitle:"",
          linkUrl:"",
        }]
      });
    },
    addVimeoHelpCenter(type, index) {
      this.help_center_details[type].data[index].vimeoVideos.push({
        linkTitle: "",
        linkUrl: "",
      });
    },
    async toggleHelpCenter(type, status) {
      if (status == 1) {
        this.help_center_details[type].data = JSON.parse(JSON.stringify(this.helpCenter[type]));
        this.help_center_details[type].status = status;
      } else {
        const confirmComplete = await this.$parent.$refs.confirmDialogue.show({
          title: "",
          message: "Are you sure you want to hide  ?",
          okButton: "Yes",
        });
        // On Confirm
        if (confirmComplete) {
          this.help_center_details[type].data = [
            {
              question: "",
              answer: "",
              icon: "",
              oldIcon: "",
              newIcon: false,
            },
          ];
          this.help_center_details[type].status = status;
        } else {
          let el = this.$refs[type + '_show'];
          el.click();
        }
      }
    },
    createHelpCenterIcon(e, type, index) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var reader = new FileReader();
      let file = files[0];

      reader.onload = (e) => {
        this.help_center_details[type].data[index].newIcon = true;
        this.help_center_details[type].data[index].icon = e.target.result;
      };

      reader.readAsDataURL(file);
    },
    removeHelpCenterIcon(type, index) {
      this.$refs[type + "_icon_ref" + index][0].type = "text";
      this.$refs[type + "_icon_ref" + index][0].type = "file";
      document.getElementById(type + "_icon_error_" + index).style.display = "none";
      this.help_center_details[type].data[index].newIcon = false;
      this.help_center_details[type].data[index].icon = this.help_center_details[type].data[index].oldIcon; // to reset old default icon
    },
    async removeHelpCenter(type, index) {
      this.help_center_details[type].data.splice(index, 1);
      var _this = this;
      await this.$nextTick(() =>
        this.help_center_details[type].data.forEach(function (item, key) {
          _this.$validator.validate('addCientForm.' + type + '_que_' + key);
          // _this.$validator.validate('addCientForm.'+type+'_icon_' + key);
          _this.$validator.validate('addCientForm.' + type + '_ans_' + key);
        })
      );
    },
    async removeVimeoHelpCenter(type, index, vimeoIndex) {
      this.help_center_details[type].data[index].vimeoVideos.splice(vimeoIndex, 1);
      var _this = this;
      await this.$nextTick(() =>
        this.help_center_details[type].data.forEach(function (item, key) {
          _this.$validator.validate('addCientForm.' + type + '_que_' + key);
          // _this.$validator.validate('addCientForm.'+type+'_icon_' + key);
          _this.$validator.validate('addCientForm.' + type + '_ans_' + key);
        })
      );
    },
    initializeFile(event, type, index) {
      //this method is called for validation error issue on chrome broswer
      this.helpCenterEvent = event.target.id;
      this.helpCenterType = type;
      this.helpCenterindex = index;
      // document.body.onfocus = this.checkFile;
      this.$nextTick(() => {
        document.body.focus = this.checkFile;
      });
    },
    checkFile() {
      var theFile = document.getElementById(this.helpCenterEvent);
      if (theFile.value.length) console.log("Help center Files Loaded");
      else this.removeHelpCenterIcon(this.helpCenterType, this.helpCenterindex);

      document.body.focus = null;
    },
  },
};
</script>